.CaseDetailWrap{
    .CaseDetailHeader{
        padding:0px 15px;
        max-width: $sitewidth;
        margin: 0 auto;
        .InnerWrap{
            margin-top: 20px;
            padding: 15px 20px;
            box-shadow: 0px 0px 25px 0px #0000001f;
            margin-bottom: 20px;
            .ant-row{
                align-items: center;
                .ant-col{
                    @media screen and (max-width: $break-ipadprolandscape) {
                        flex: 0 0 100%;
                        max-width: 100%;
                        text-align: left !important;
                        margin-top: 30px;
                    }
                    p{
                        &.CaseId{
                            margin-bottom: 0px;
                            font-size: 20px;
                            font-weight: bold;
                            color: #4D4F5C;
                            @media screen and (max-width: $break-ipadproportrait) {
                                font-size: 16px;
                            }
                            span{
                                font-weight: normal;
                                display: inline-block;
                                margin-left: 40px;
                                @media  screen and (max-width: $break-mobile) {
                                    margin-left: 0px;
                                }
                                span{
                                    border: 1px solid #4D4E53;
                                    margin-left: 20px;
                                    padding: 0px 40px;
                                    @media  screen and (max-width: $break-mobile) {
                                        padding: 0px 15px;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2){
                        text-align: right;
                      
                        button{
                            background-color: $primary;
                            color: #fff;
                            font-size: 14px;
                            border-color: transparent;
                            border: none;
                            box-shadow: none;
                           height: 38px;
                           margin-right: 10px;
                           margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
