@font-face {
  font-family: 'Volvo Novum';
  src: url('../fonts/VolvoNovum-Regular.woff2') format('woff2'),
    url('../fonts/VolvoNovum-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('../fonts/VolvoNovum-LightItalic.woff2') format('woff2'),
    url('../fonts/VolvoNovum-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('../fonts/VolvoNovum-MediumItalic.woff2') format('woff2'),
    url('../fonts/VolvoNovum-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('../fonts/VolvoNovum-Italic.woff2') format('woff2'),
    url('../fonts/VolvoNovum-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('../fonts/VolvoNovum-Light.woff2') format('woff2'),
    url('../fonts/VolvoNovum-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('../fonts/VolvoNovum-Medium.woff2') format('woff2'),
    url('../fonts/VolvoNovum-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum SemiLight';
  src: url('../fonts/VolvoNovum-SemiLightItalic.woff2') format('woff2'),
    url('../fonts/VolvoNovum-SemiLightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Volvo Novum SemiLight';
  src: url('../fonts/VolvoNovum-SemiLight.woff2') format('woff2'),
    url('../fonts/VolvoNovum-SemiLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

//Screen Breakpoints

$break-laptoplg: 1640px;
$break-abovelaptop: 1590px;
$break-laptoplg1440: 1440px;
$break-laptop: 1366px;
$break-laptop1365: 1365px;
$break-belowlaptop: 1280px;
$break-MDPI: 1180px;
$break-ipadprolandscape: 1024px;
$break-ipadproportrait: 991px;
$break-ipad: 767px;
$break-mobile: 667px;
$break-mobile580: 580px;
$break-mobilesmall: 448px;
$break-mobile380: 380px;
$break-mobilemini: 320px;

//Font Size
$font40:40px;

//Font Weight
$weight-bold:bold;
$weight-semi:600;
$weight-normal:400;
$weight-regular:300;
$weight-light:100;

//Site Width
$sitewidth:1340px;

//FOnt-color

$white:#fff;
$black:#000;
$yellow:#FF9900;
$primaryLink:#29ABE2;
$lightGreen:#8CC63F;
//$primary: #1251b5;
$primary: #202A44;
$red:#DD0303;
$lite:#eff1f2;
$litedark:#5e5f64;

//Global use Css 
body {
  font-family: 'Volvo Novum' !important;
}

// body a {
//   color: $primary;

//   &:hover {
//     color: rgba(21, 29, 47, 1);
//   }
// }

.ant-tabs-tab-btn {
  &:focus {
    color: #fff;
  }
}

.ant-btn,
.ant-btn:focus {

  &:focus,
  &:hover {
    color: #fff;
    border-color: rgba(21, 29, 47, 1);
  }
}
.ant-btn.btn-blank{

  &:focus,
  &:hover {
    color: #4d4e53;
    border-color: rgba(21, 29, 47, 1);
  }

}
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-0 {
  margin: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.w-100 {
  width: 100% !important;
}

.text-sm-left {
  @media screen and (max-width: $break-ipad) {
    text-align: left !important;
  }
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-sm-start {
  @media screen and (max-width: $break-ipad) {
    justify-content: flex-start !important;
  }
}

//global overwrite
.ant-radio-group,
.ant-table-cell {
  .ant-radio-wrapper {
    .ant-radio {
      .ant-radio-inner {
        &:after {
          background-color: $primary;
          top: 2px;
          left: 2px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

//placeholder mixin
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }
}

.align-items-center {
  align-items: center !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.Btndefault {
  background-color: $primary !important;
  color: $white !important;
  font-size: 14px !important;
  border-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  height: 38px !important;

}

.ant-form-item-label {
  label {
    &:before {
      position: absolute;
      right: -10px;
    }
  }
}