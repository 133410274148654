.TableWrap {
  overflow-x: auto;
  margin-bottom: 20px;

  table {
    width: 1400px;

    thead {
      tr {
        background-color: #d8d7d5;

        th {
          padding: 20px;
          font-weight: normal;
          font-size: 16px;
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f5f5f5;
        }
      }

      td {
        padding: 20px;
        font-weight: normal;
        font-size: 16px;
        text-align: left;
      }
    }
  }
}

.SmallTableWrap {
  overflow-x: auto;
  margin-bottom: 20px;

  table {
    width: 100%;

    thead {
      tr {
        background-color: #d8d7d5;

        th {
          padding: 20px;
          font-weight: normal;
          font-size: 16px;
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f5f5f5;
        }
      }

      td {
        padding: 20px;
        font-weight: normal;
        font-size: 16px;
        text-align: left;
      }
    }
  }
}

.KycCheck {
  .ant-modal-body {
    .confirmationtext {
      text-align: center;
      color: #009e0f;
      font-size: 20px;
      font-weight: normal;
      padding-top: 40px;
    }

    .dangerconfirmationtext {
      text-align: center;
      color: red;
      font-size: 20px;
      font-weight: normal;
      padding-top: 40px;
    }

    .successconfirmationtext {
      text-align: center;
      color: green($color: #28a94a);
      font-size: 20px;
      font-weight: normal;
      padding-top: 40px;
    }
  }

  .ant-modal-footer {
    .ant-btn {
      background-color: #fff;
      color: #4d4e53;
      border-color: #4d4e53;
      padding: 0px 60px;
      font-size: 14px;
      font-weight: normal;
      height: 38px;
      line-height: normal;
      margin-right: 15px;
      margin-bottom: 10px;

      &.ant-btn-primary {
        background-color: #202A44;
        border-color: #202A44;
        color: #fff;
      }
    }
  }
}

.StatusHighlight {
  margin-top: 20px;
  padding: 15px 0px;
  margin-bottom: 20px;

  .ButtonWrap {
    text-align: right;

    @media screen and (max-width: $break-ipad) {
      text-align: left;
      margin-top: 10px;
    }

    button {
      background-color: #202A44;
      color: #fff;
      border-color: #202A44;
      border-radius: 0px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.NoCardBox {
  margin-bottom: 20px;
  box-shadow: 0px 0px 25px 0px #0000001f;
  padding: 30px;
  text-align: center;

  p {
    font-weight: 700;
    font-size: 25px;
    text-transform: uppercase;
  }
}

button {
  &.AddField {
    background-color: $primary !important;
    color: $white !important;
    font-size: 12px !important;

    &:hover,
    &:focus {
      background-color: $primary !important;
      color: $white !important;
    }
  }
}

.HeaderPanelAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: $break-mobile580) {
    display: block;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    color: #4d4f5c;

    @media screen and (max-width: $break-ipadproportrait) {
      font-size: 16px;
    }
  }

  button {
    background-color: $primary;
    color: $white;
    border-color: $primary;
    border-radius: 0px;
  }

  .ButtonWrap {
    button {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .HeadingButton {
    display: flex;
    align-items: center;

    @media screen and (max-width: $break-ipadproportrait) {
      display: block;
    }

    h3 {
      margin-bottom: 0px;
    }

    button {
      margin-right: 10px;
      border-color: #4d4e53;
      color: #4d4e53;
      background-color: #fff;
    }
  }
}

.ant-modal-content {
  border-radius: 10px;

  .ant-modal-header {
    border-radius: 10px;

    border-bottom: 0px;

    .ant-modal-title {
      text-align: center;
      color: #009e0f;
      font-size: 20px;
      font-weight: normal;
    }
  }

  .ant-modal-body {
    padding-top: 0px;

    .ant-form-item-control {
      .ant-upload {
        button {
          img {
            padding-left: 10px;
            margin-bottom: 3px;
          }
        }
      }
    }

    .CollapseWrap {
      h3 {
        &.heading {
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #4d4f5c;
        }
      }

      .ant-collapse {
        border: none;

        .ant-collapse-item {
          margin-bottom: 15px;
          border: 1px solid #cdd4d9;
          border-radius: 5px;

          &.completed {
            .ant-collapse-header {
              &:before {
                content: "";
                width: 18px;
                height: 18px;
                background-image: url("../images/completedcheck.png");
                position: absolute;
                left: 10px;
                background-size: contain;
                background-repeat: no-repeat;
                top: 12px;
              }
            }
          }

          &.loading {
            .ant-collapse-header {
              &:before {
                content: "";
                width: 18px;
                height: 18px;
                background-image: url("../images/loading.png");
                position: absolute;
                left: 10px;
                background-size: contain;
                background-repeat: no-repeat;
                top: 12px;
              }
            }
          }

          .ant-collapse-header {
            font-size: 16px;
            color: #999999;
            padding-top: 5px;
            padding-bottom: 5px;

            .anticon {
              position: absolute;
              right: 10px;
              text-align: right;
              left: unset;
              transform: rotate(90deg);
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              -o-transform: rotate(90deg);
              top: 10px;
              padding: 0px;
            }

            &:after {
              content: "";
              width: 1px;
              background-color: #cdd4d9;
              position: absolute;
              right: 32px;
              height: 100%;
              top: 0;
            }
          }

          &.ant-collapse-item-active {
            .ant-collapse-header {
              .anticon {
                transform: rotate(-180deg);
                -webkit-transform: rotate(-180deg);
                -moz-transform: rotate(-180deg);
                -ms-transform: rotate(-180deg);
                -o-transform: rotate(-180deg);
              }
            }
          }

          .ant-collapse-content {
            .ant-collapse-content-box {
              ul {
                padding: 0px;

                li {
                  list-style-type: none;
                  color: #999999;
                  font-size: 16px;
                  position: relative;

                  &.completed {
                    &:after {
                      content: "";
                      width: 18px;
                      height: 18px;
                      background-image: url("../images/completedcheck.png");
                      position: absolute;
                      right: 10px;
                      background-size: contain;
                      background-repeat: no-repeat;
                      top: 5px;
                    }
                  }

                  &.failed {
                    &:after {
                      content: "";
                      width: 18px;
                      height: 18px;
                      background-image: url("../images/invalid.png");
                      position: absolute;
                      right: 10px;
                      background-size: contain;
                      background-repeat: no-repeat;
                      top: 5px;
                    }
                  }

                  &.loading {
                    &:after {
                      content: "";
                      width: 18px;
                      height: 18px;
                      background-image: url("../images/loading.png");
                      position: absolute;
                      right: 10px;
                      background-size: contain;
                      background-repeat: no-repeat;
                      top: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.uploadfield {
  button {
    img {
      position: absolute;
      right: 0px;
      border-left: 1px solid #cdd4d9;
      padding: 8px;
      top: 0;
      height: 100%;
    }
  }
}

.ant-form-item-label {
  >label {
    display: inline;
    height: auto;
  }
}

.ant-modal-title {
  font-weight: 600 !important;
}

.errorMessage {
  color: red;
  font-weight: 400;
  padding-bottom: 10px;
  font-size: large;
}

.smallErrorMessage {
  color: red;
  font-weight: 400;
  padding-bottom: 10px;
  // font-size: medium;
}

.noteMessage {
  color: rgba(0, 0, 0, .45);
}

.labelbottom {
  position: relative;

  .bottom {
    position: absolute;
    bottom: -20px;
    right: 0;
    margin-bottom: 0px;
  }
}

.edit-participant-details {
  .ant-form-item-control {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.bureau-report-summary-table {
  .ant-card-body {
    overflow: auto;
  }
}

.kyc-summary-header {
  th {
    border-radius: unset !important;
    padding-top: 6px !important;
  }

  .kyc-summary-inner-paragraph {
    font-size: 13px !important;
    padding: 0 !important;
    padding-left: 5px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom: 1px solid #b3b3b3;
    border-right: unset !important;
    height: 40px !important;
  }
}

.SubHeading {
  font-weight: 400;
  margin-left: 5px;
}

.requiredAsterik {
  position: relative;

  &::before {
    position: absolute;
    display: inline-block;
    top: -8px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.arranged-button {
  justify-content: flex-end !important;
}

.NotificationsList {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 30px;

  h3 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 30px;

    svg {
      fill: #1251b5;
    }
  }

  ul {
    max-height: 590px;
    overflow-y: auto;

    a {
      &:nth-child(1) {
        li {
          border-top: unset !important;
        }
      }
    }

    li {
      //border-left: 5px solid #1251b5 !important;
      //margin-bottom:10px;
      //border:1px solid #ddd;
      padding: 10px;
      position: relative;
      border-top: 2px solid #ddd !important;

      &.unread {
        background-color: #ddd;
      }

      &.read {
        background-color: #fff;
      }

      .timeblock {
        display: flex;
        align-items: center;
        position: absolute;
        top: 10px;
        right: 20px;
        color: #000000a3;

        span {
          svg {
            margin: 0px 10px;
            //fill: #1251B5;
            width: 16px;
            height: 16px;
          }
        }
      }

      .ant-list-item-meta-title {
        font-size: 16px;
        font-weight: 500;
      }

      .ant-list-item-meta-description {
        font-size: 14px;
        color: #4d4e53;
      }
    }
  }

  .ant-list-split {
    padding: 20px;
    box-shadow: 2px 2px 2px 2px #ddd;
    border-radius: 5px;
  }
}

.Approved {
  color: #009e0f;
  font-weight: 500;
}

.Dropped {
  color: #ff4d4f;
  font-weight: 500;
}

.Added {
  color: #f1c40f;
  font-weight: 500;
}

.unread {
  background-color: #ddd;
}

.errorastrick {
  color: red;
}

.dashBoardWrap {
  .ViewDataCardWrap {
    .ant-card {
      .ant-card-body {
        .HeaderSelector {
          form {
            .ant-form-item {
              margin-right: 16px;
              margin-bottom: 10px;
            }

            .ant-space-horizontal {
              margin-bottom: 10px;
            }
          }

          .ml-20 {
            margin-left: 20px !important;
          }
        }

        .ant-space-item {
          .ant-btn-primary:focus {
            background: #202A44;
            background-color: #202A44;
          }
        }
      }
    }
  }
}

button {
  &.btn-filled {
    background-color: #202A44;
    color: #fff;
    font-size: 14px;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 0px 5px;
    min-width: 105px;

    &:hover {
      background-color: #202A44;
    }
  }

  &.btn-blank {
    border: 1px solid #4d4e53;

    padding: 0px 40px;
    background-color: transparent;
    color: #4d4e53;
    min-width: 105px;

    &:hover {
      background-color: transparent;
      border: 1px solid #4d4e53;
      color: #4d4e53;
    }
  }
}

.NotificationsList ul::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.NotificationsList ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.NotificationsList ul::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
}

/* Handle on hover */
.NotificationsList ul::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.btn-info {
  cursor: pointer;
}

button {
  &.ant-btn-primary {
    border-color: #202A44;
  }

}

/*Date picker css*/
.ant-picker-today-btn {
  color: #202A44 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today {
  .ant-picker-cell-inner {
    &::before {
      border: 1px solid #202A44 !important;
    }
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: #202A44 !important;
  }
}

// .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
//   background: #202A44 !important;
// }

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-range .ant-picker-active-bar {
  background: #202A44 !important;
}

.ant-pagination-item-active {
  border-color: #202A44 !important;

  a {
    color: #202A44 !important;
  }
}

.ant-pagination-item {
  &:hover {
    color: #202A44;
    border-color: #202A44 !important;

    a {
      color: #202A44 !important;
    }
  }
}

.ant-pagination-item-active {
  a {
    color: #202A44;
  }

}

.SmallTableWrap-v2 {


  .ant-btn {

    &:hover,
    &:focus {
      color: #4d4e53 !important;
      border-color: #151d2f !important;
    }
  }
}

.btn-dark {
  border-color: #202A44 !important;
  background-color: #202A44 !important;

  &:focus,
  &:hover {
    color: #fff;
    border-color: #202A44 !important;
    background-color: #202A44 !important;
  }
}

.btn-light-with-lable-dark {
  color: #4D4E53 !important;
  border-color: #4D4E53 !important;
  background-color: #fff !important;

  &:focus,
  &:hover {
    color: #fff !important;
    color: #4d4e53 !important;
    border-color: #151d2f !important;
  }
}
.BtnSignOut{
  &:focus, &:hover{
    color:$primary !important;
    border-color: $primary !important;
  }
}
.UploadCard{
  .ant-upload-list-item-card-actions{
    .ant-upload-list-item-card-actions-btn{
      opacity: 1;
      svg{
        height: 18px;
        width: 18px;
      }
    }
  }
}
.AuthSingle{
  .InnerWrap{
    .Branding{
      min-width: 350px;

    }
  }
  .sso-login-btn { 
    margin-bottom: 30px;
    padding: 0px 10px;
    a {
      width: 100%;
     
      background-color: #202a44 !important;
      border-color: #202a44;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 10px !important;
      margin: 0 auto;
      line-height: unset !important;
      height: unset;
    }
  }
}


.ant-radio-inner{
  border-color: #000 !important;
  
}
.DaysAgoText{
  top:26px !important;
  right: 8px !important;
}
.rightHeading span{
  display: inline-block;
  /* text-align: right; */
  float: right;
  font-weight: 400;
}
.AboveButtonNote{
  margin-bottom: 15px;
}
.ant-upload{
  button{
    &:focus, &:active{
      color: inherit !important;
    }
  }
}
.LoginAsAdmin{
  margin-right: 15px;
  padding: 5px;
  border: 1px solid #202a44;
  color: #202a44;
  &:focus, &:hover{
    border: 1px solid #202a44;
    color: #202a44;
  }
}
.fleet-analy.FilterForm {
  form {
    .ant-row {
      .ant-col{align-items: baseline;}
    }
  }
  
}
.JustReqWrap {
  .ant-modal-content{
    .ant-modal-footer{
      button{
        width: auto;
        padding: 0 30px;
      }
    }
  }
}
.add-field-fleet{
  margin-top: 41px;
  position: relative;
  left: -80px;
}
@media screen and (max-width: $break-mobile580) {
  .add-field-fleet{
    margin-top: 0px;
    position: relative;
    left: 0px;
  }
  .CaseListDataMobile{
    .ant-row{display: block;
      .ant-col-12{
        max-width: 100%;
      }
    }
  }
  .formexpandedfilterMobile{
    .ant-row{
      .ant-col-3, .ant-col-8, .ant-col-13{
        max-width: 100%;
        flex: 0 0 100%;
      }
      .ant-col-3{
        order: 1;
      }
      .ant-col-8{
        order: 2;
      }
      .ant-col-13{
        order: 0;
      }
    }
  }
}


li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
  padding: 0 !important;
  line-height: 0 !important;
}
.NavWrap ul.ant-menu li a{padding: 20px 20px; line-height: 58px;}


.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: 2px solid transparent !important;
}
// .vfs-collapse{background-color: rgba(0,0,0,.03);}

.FieldDetailTabs .ant-tabs .ant-collapse.vfs-collapse .ant-collapse-item .ant-collapse-header{background-color: rgba(0,0,0,.03);}
.ant-collapse.vfs-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text{font-size: 1rem;
  color: rgb(32, 42, 68);
  font-weight: bold;}
.FieldDetailTabs .ant-tabs .ant-collapse.vfs-collapse .ant-collapse-item .ant-collapse-header:before{top: 18px;transform: rotate(-0deg);}
.ant-collapse.vfs-collapse {background: none;}