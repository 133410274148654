.ModalFormWrap {
    .ant-modal-close {
        background-color: $primary;
        position: absolute;
        right: -20px;
        color: $white;
        border-radius: 50%;
        top: -15px;

        .ant-modal-close-x {
            width: 25px;
            height: 25px;
            line-height: 25px;
        }

        &:hover {
            color: $white;
        }

        @media screen and (max-width: $break-MDPI) {

            top: unset;
            right: 10px;
            background-color: transparent;
            color: #000;
        }
    }

    .ant-modal-header {
        .ant-modal-title {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .ModalFormInnerWrap {
        .ant-tabs {
            .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                    width: 100%;

                    .ant-tabs-nav-list {
                        width: 100%;
                        border-bottom: 2px solid #7E8088;

                        .ant-tabs-tab {
                            width: 33%;

                            @media screen and (max-width: $break-ipadproportrait) {

                                width: auto;
                            }

                            .ant-tabs-tab-btn {
                                width: 100%;
                                text-align: center;
                                font-size: 20px;

                                @media screen and (max-width: $break-ipadproportrait) {
                                    font-size: 16px;

                                }

                                &:hover {
                                    color: $primary;
                                }
                            }

                            &.ant-tabs-tab-active {
                                .ant-tabs-tab-btn {
                                    color: $primary;
                                }
                            }
                        }

                        .ant-tabs-ink-bar {
                            background: $primary;
                            bottom: -1px;
                        }
                    }
                }
            }

            .ant-tabs-content-holder {
                .ant-tabs-content {
                    .ant-tabs-tabpane {
                        .DataWrap {
                            .CardDetailWrap {
                                h3 {
                                    margin: 20px 0px;
                                    font-size: 20px;
                                    font-weight: bold;
                                }

                                .ant-card {
                                    border-radius: 10px;
                                    

                                    .ant-card-body {
                                        padding: 30px 20px;
                                        box-shadow: 0px 0px 25px 0px #0000001f;

                                        .ant-row {
                                            .ant-col {
                                                h4 {
                                                    font-size: 14px;
                                                    font-weight: bold;
                                                }

                                                p {
                                                    font-size: 14px;
                                                    font-weight: normal;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .DocumentFormWrap {
            h4 {
                &.heading {
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            form {
                max-width: 700px;
                margin-right: auto;

                .applicantype {
                    &.ant-form-item {
                        margin-bottom: 0px;

                        .ant-form-item-label {
                            text-align: left;
                            color: #4D4F5C;
                            font-size: 14px;
                            font-weight: normal;
                        }
                    }

                    &.ApplicantSelector {
                        &.ant-form-item {
                            margin-bottom: 20px;
                        }
                    }
                }

                .ant-card {
                    border: 0px;

                    .ant-card-head {
                        padding: 0px;
                        font-size: 14px;
                        font-weight: bold;
                        border: 0px;
                    }

                    .ant-card-body {
                        padding-left: 0px;
                        padding-right: 0px;

                        .ant-form-item {
                            .ant-form-item-label {
                                text-align: left;
                                color: #4D4F5C;
                                font-size: 14px;
                                font-weight: normal;
                            }

                            .ant-form-item-control {
                                .ant-form-item-control-input {
                                    .ant-form-item-control-input-content {
                                        .ant-upload {
                                            width: 100%;

                                            .ant-upload {
                                                button {
                                                    width: 100%;
                                                    position: relative;
                                                    text-align: left;
                                                    border: 1px solid #CDD4D9;
                                                    border-radius: 5px;
                                                    color: #999999;

                                                    img {
                                                        position: absolute;
                                                        right: 0px;
                                                        border-left: 1px solid #CDD4D9;
                                                        padding: 8px;
                                                        top: 0;
                                                        height: 100%;
                                                    }

                                                    &:hover {
                                                        color: #999999;
                                                    }

                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }

                    }

                    .btnWrap {
                        text-align: right;

                        button {
                            background-color: $primary;
                            color: $white;
                            width: 107px;
                            height: 38px;
                        }
                    }

                    table {
                        width: 100%;
                        margin-top: 20px;
                        border-radius: 5px;
                        table-layout: fixed;

                        thead {
                            tr {
                                th {
                                    padding: 20px;
                                    background-color: #D8D7D5;
                                    text-align: center;
                                    font-weight: normal;
                                    font-size: 18px;
                                    width: 50%;

                                    &:nth-child(1) {
                                        border-right: 1px solid #fff;
                                        border-top-left-radius: 5px;
                                    }

                                    &:nth-child(2) {
                                        border-top-right-radius: 5px;
                                    }

                                    @media screen and (max-width: $break-mobile) {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    padding: 20px;
                                    text-align: center;
                                    font-size: 12px;
                                }
                            }
                        }

                        &.coapptype {
                            thead {
                                tr {
                                    th {

                                        &:nth-child(1) {
                                            border-right: 1px solid #fff;
                                            border-top-left-radius: 5px;
                                        }

                                        &:nth-child(2) {
                                            border-right: 1px solid #fff;
                                        }

                                        &:nth-child(3) {
                                            border-top-right-radius: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .BtnWrapTab {
                    text-align: center;

                    button {
                        padding: 0px 60px;
                        background-color: $primary;
                        border-color: $primary;
                        font-size: 14px;
                        font-weight: normal;
                        height: 38px;
                        line-height: normal;
                        margin-right: 15px;
                        color: $white;
                        margin-bottom: 10px;

                        &:nth-child(1) {
                            background-color: $white;
                            color: #4D4E53;
                            border-color: #4D4E53;
                        }
                    }
                }

                .MainBtnWrap {
                    button {
                        padding: 0px 60px;
                        background-color: $primary;
                        border-color: $primary;
                        font-size: 14px;
                        font-weight: normal;
                        height: 38px;
                        line-height: normal;
                        margin-right: 15px;
                        color: $white;
                        margin-bottom: 10px;

                        &:nth-child(1) {
                            background-color: $white;
                            color: #4D4E53;
                            border-color: #4D4E53;
                        }
                    }

                    a {
                        padding: 9px 60px;
                        background-color: $primary;
                        border-color: $primary;
                        font-size: 14px;
                        font-weight: normal;
                        height: 38px;
                        line-height: normal;
                        margin-right: 15px;
                        color: $white;
                        margin-bottom: 10px;
                        display: inline-block;
                    }
                }
            }
        }
    }

}

.KycCheck {
    .ant-modal-close {
        background-color: $primary;
        position: absolute;
        right: -20px;
        color: $white;
        border-radius: 50%;
        top: -15px;

        .ant-modal-close-x {
            width: 25px;
            height: 25px;
            line-height: 25px;
        }

        &:hover {
            color: $white;
        }

        @media screen and (max-width: $break-MDPI) {

            top: unset;
            right: 10px;
            background-color: transparent;
            color: #000;
        }
    }

    .ant-modal-content {
        border-radius: 10px;

        .ant-modal-header {
            border-radius: 10px;

            border-bottom: 0px;

            .ant-modal-title {
                text-align: center;
                color: #009E0F;
                font-size: 20px;
                font-weight: normal;
            }
        }

        .ant-modal-body {
            padding-top: 0px;

            .CollapseWrap {
                h3 {
                    &.heading {
                        text-align: center;
                        font-size: 20px;
                        font-weight: bold;
                        color: #4D4F5C;
                    }
                }

                .ant-collapse {
                    border: none;

                    .ant-collapse-item {
                        margin-bottom: 15px;
                        border: 1px solid #CDD4D9;
                        border-radius: 5px;

                        &.completed {
                            .ant-collapse-header {
                                &:before {
                                    content: '';
                                    width: 18px;
                                    height: 18px;
                                    background-image: url('../images/completedcheck.png');
                                    position: absolute;
                                    left: 10px;
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    top: 12px;
                                }
                            }

                        }

                        &.loading {
                            .ant-collapse-header {
                                &:before {
                                    content: '';
                                    width: 18px;
                                    height: 18px;
                                    background-image: url('../images/loading.png');
                                    position: absolute;
                                    left: 10px;
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    top: 12px;
                                }
                            }

                        }

                        .ant-collapse-header {
                            font-size: 16px;
                            color: #999999;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-left: 16px;

                            .anticon {
                                position: absolute;
                                right: 10px;
                                text-align: right;
                                left: unset;
                                transform: rotate(90deg);
                                -webkit-transform: rotate(90deg);
                                -moz-transform: rotate(90deg);
                                -ms-transform: rotate(90deg);
                                -o-transform: rotate(90deg);
                                top: 10px;
                                padding: 0px;
                            }

                            &:after {
                                content: '';
                                width: 1px;
                                background-color: #CDD4D9;
                                position: absolute;
                                right: 32px;
                                height: 100%;
                                top: 0;
                            }
                        }

                        &.ant-collapse-item-active {
                            .ant-collapse-header {
                                .anticon {
                                    transform: rotate(-180deg);
                                    -webkit-transform: rotate(-180deg);
                                    -moz-transform: rotate(-180deg);
                                    -ms-transform: rotate(-180deg);
                                    -o-transform: rotate(-180deg);
                                }
                            }
                        }

                        .ant-collapse-content {
                            .ant-collapse-content-box {
                                ul {
                                    padding: 0px;

                                    li {
                                        list-style-type: none;
                                        color: #999999;
                                        font-size: 16px;
                                        position: relative;

                                        &.completed {
                                            &:after {
                                                content: '';
                                                width: 18px;
                                                height: 18px;
                                                background-image: url('../images/completedcheck.png');
                                                position: absolute;
                                                right: 10px;
                                                background-size: contain;
                                                background-repeat: no-repeat;
                                                top: 5px;
                                            }
                                        }

                                        &.failed {
                                            &:after {
                                                content: '';
                                                width: 18px;
                                                height: 18px;
                                                background-image: url('../images/invalid.png');
                                                position: absolute;
                                                right: 10px;
                                                background-size: contain;
                                                background-repeat: no-repeat;
                                                top: 5px;
                                            }
                                        }

                                        &.loading {
                                            &:after {
                                                content: '';
                                                width: 18px;
                                                height: 18px;
                                                background-image: url('../images/loading.png');
                                                position: absolute;
                                                right: 10px;
                                                background-size: contain;
                                                background-repeat: no-repeat;
                                                top: 5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .CollapseWrap-v2 {
                table {
                    tr {
                        td {
                            &:first-child {
                                label {
                                    list-style-type: none;
                                    color: #999999;
                                    font-size: 16px;
                                    position: relative;
                                    width: 170px;
                                    padding-right: 5px;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.BeureauReport {
    .ant-modal-content {
        border-radius: 10px;

        .ant-modal-close {
            background-color: $primary;
            position: absolute;
            right: -20px;
            color: $white;
            border-radius: 50%;
            top: -15px;

            .ant-modal-close-x {
                width: 25px;
                height: 25px;
                line-height: 25px;
            }

            &:hover {
                color: $white;
            }

            @media screen and (max-width: $break-MDPI) {

                top: unset;
                right: 10px;
                background-color: transparent;
                color: #000;
            }
        }

        .ant-modal-header {
            border-radius: 10px;
            border: none;

            .ant-modal-title {
                text-align: center;
                font-size: 20px;
                color: #4D4F5C;
            }
        }

        .ant-modal-body {
            .BureauFormWrap {
                form {
                    .ant-row {
                        .ant-col {
                            .ant-form-item {
                                .ant-form-item-label {
                                    label {
                                        color: #999999;
                                        font-size: 16px;
                                        font-weight: bold;
                                    }
                                }

                                .ant-form-item-control {
                                    .ant-form-item-control-input {
                                        .ant-form-item-control-input-content {
                                            .ant-checkbox-wrapper {
                                                .ant-checkbox {
                                                    .ant-checkbox-inner {
                                                        background-color: #fff;
                                                        border-color: #707070;

                                                    }

                                                    &.ant-checkbox-checked {
                                                        &:after {
                                                            border: 1px solid #009E0F;
                                                        }

                                                        .ant-checkbox-inner {
                                                            &:after {
                                                                border-color: #009E0F;
                                                            }
                                                        }
                                                    }
                                                }

                                                span {
                                                    font-size: 14px;
                                                    color: #4D4F5C;
                                                    font-weight: normal;
                                                }
                                            }

                                            >p {
                                                font-size: 14px;
                                                color: #4D4F5C;
                                                font-weight: bold;
                                            }

                                            .ant-row {
                                                .ant-col {
                                                    label {
                                                        font-size: 14px;
                                                        color: #4D4F5C;
                                                        font-weight: normal;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.applicantName {
                                    .ant-form-item-control {
                                        max-width: 350px;
                                    }
                                }
                            }

                            .LeftInfoArea {
                                height: 100%;
                                padding-left: 60px;

                                @media screen and (max-width: $break-mobile580) {
                                    padding-left: 10px;
                                }

                                &:before {
                                    content: '';
                                    height: 100%;
                                    width: 1px;
                                    background-color: #4D4F5C;
                                    position: absolute;
                                    left: 0;
                                    top: 0;

                                    @media screen and (max-width: $break-mobile580) {
                                        display: none;
                                    }
                                }

                                h4 {
                                    color: #999999;
                                    font-size: 16px;
                                    font-weight: bold;
                                }

                                .ant-row {
                                    margin-bottom: 0px;

                                    .ant-col {
                                        p {
                                            margin-bottom: 0px;
                                        }

                                        label {

                                            font-weight: bold !important;
                                        }
                                    }
                                }
                            }

                            .FetchBtn {

                                padding: 9px 60px;
                                background-color: $primary;
                                border-color: $primary;
                                font-size: 14px;
                                font-weight: normal;
                                height: 38px;
                                line-height: normal;
                                margin-right: 15px;
                                color: $white;
                                margin-bottom: 10px;
                                display: inline-block;

                            }
                        }
                    }
                }
            }
        }
    }

}

.JustReqWrap {
    .ant-modal-content {
        border-radius: 10px;

        .ant-modal-close {
            background-color: $primary;
            position: absolute;
            right: -20px;
            color: $white;
            border-radius: 50%;
            top: -15px;
            display: none;

            .ant-modal-close-x {
                width: 25px;
                height: 25px;
                line-height: 25px;
            }

            &:hover {
                color: $white;
            }

            @media screen and (max-width: $break-MDPI) {
                top: unset;
                right: 10px;
                background-color: transparent;
                color: #000;
            }
        }

        .ant-modal-header {
            border-radius: 10px;
            border: none;

            .ant-modal-title {
                text-align: center;
                font-size: 20px;
                color: #4D4F5C;
            }
        }

        .ant-modal-body {

            .TextWrap {
                h4 {
                    font-size: 18px;
                    font-weight: bold;
                    color: #4D4F5C;
                }
            }

            .ant-card {
                .ant-card-body {
                    box-shadow: 0px 0px 25px 0px #0000001f;
                    border-radius: 10px;

                    p {
                        margin-bottom: 0px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #4D4F5C;
                    }
                }
            }
        }

        .ant-modal-footer {
            text-align: center;
            border: none;
            padding-bottom: 20px;

            button {
                width: 141px;
                height: 38px;
                font-size: 14px;

                &:nth-child(1) {
                    color: #4D4F5C;
                    border-color: #4D4F5C;
                }

                &:nth-child(2) {
                    color: $white;
                    background-color: $primary;
                }
            }
        }
    }
}

.AddCoapplicant {

    .ant-modal-content {
        border-radius: 10px;

        .ant-modal-close {
            background-color: $primary;
            position: absolute;
            right: -20px;
            color: $white;
            border-radius: 50%;
            top: -15px;

            .ant-modal-close-x {
                width: 25px;
                height: 25px;
                line-height: 25px;
            }

            &:hover {
                color: $white;
            }

            @media screen and (max-width: $break-MDPI) {

                top: unset;
                right: 10px;
                background-color: transparent;
                color: #000;
            }
        }

        .ant-modal-header {
            border-radius: 10px;
            border: none;

            .ant-modal-title {
                text-align: center;
                font-size: 20px;
                color: #4D4F5C;
            }
        }

        .ant-modal-body {
            .btn-label-overclip {
                span {

                    display: inline-block;
                    flex: auto;
                    display: inline-block;
                    width: 85%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .TextWrap {
                h4 {
                    font-size: 18px;
                    font-weight: bold;
                    color: #4D4F5C;
                }
            }

            .ant-card {
                .ant-card-body {
                    box-shadow: 0px 0px 25px 0px #0000001f;
                    border-radius: 10px;

                    p {
                        margin-bottom: 0px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #4D4F5C;
                    }
                }
            }

            form {
                margin-top: 20px;

                .ant-row {
                    .ant-col {
                        h4 {
                            margin-bottom: 25px;
                            font-size: 14px;
                            font-weight: bold;
                            color: #4D4F5C;
                        }

                        .ant-form-item {
                            .ant-form-item-label {
                                text-align: left;
                                white-space: normal;

                                label {
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #4D4F5C;

                                    &:after {
                                        display: none;
                                    }
                                }

                            }

                            .ant-form-item-control {
                                .ant-form-item-control-input {
                                    .ant-form-item-control-input-content {
                                        .ant-upload {
                                            width: 100%;

                                            .ant-upload {
                                                button {
                                                    width: 100%;
                                                    position: relative;
                                                    text-align: left;
                                                    border: 1px solid #CDD4D9;
                                                    border-radius: 5px;
                                                    color: #999999;

                                                    img {
                                                        position: absolute;
                                                        right: 0px;
                                                        border-left: 1px solid #CDD4D9;
                                                        padding: 8px;
                                                        top: 0;
                                                        height: 100%;
                                                        background-color: #fff;
                                                    }

                                                    &:hover {
                                                        color: #999999;
                                                    }
                                                }
                                            }

                                            button {
                                                overflow: hidden;

                                                span {

                                                    // overflow: hidden;
                                                    // max-width: 100px;
                                                    // white-space: nowrap;
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .AddField {
                            text-align: right;

                            button {
                                background-color: $primary;
                                color: $white;
                                width: 107px;
                                height: 38px;
                                font-size: 14px;
                            }
                        }

                        table {
                            width: 100%;

                            thead {
                                tr {
                                    th {
                                        background-color: #7E8088;
                                        height: 57px;
                                        color: $white;
                                        font-size: 18px;
                                        width: 50%;
                                        font-weight: normal;
                                        text-align: center;

                                        &:nth-child(1) {
                                            border-right: 1px solid $white;
                                            border-top-left-radius: 10px;
                                        }

                                        &:nth-child(2) {
                                            border-top-right-radius: 10px;
                                        }
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        padding: 10px;
                                        text-align: center;
                                        font-size: 12px;

                                        button {
                                            background-color: $primary;
                                            color: $white;
                                            font-size: 12px;

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .ant-modal-footer {
            text-align: center;
            border: none;
            padding-bottom: 20px;

            button {
                width: 141px;
                height: 38px;
                font-size: 14px;
                margin-right: 20px;

                &:nth-child(1) {
                    color: #4D4F5C;
                    border-color: #4D4F5C;
                }

                &.filled {
                    color: $white;
                    background-color: $primary;
                }
            }
        }
    }

}

.DirectModalStyle{
    width: 900px !important;
    top:70px !important
}