.FilterList {
    padding: 15px;
    max-width: $sitewidth;
    margin: 0 auto;

    .HeadWrap {
        margin-top: 20px;
        padding: 10px 20px;
        box-shadow: 0px 0px 25px 0px #0000001f;

        h3 {
            font-size: 20px;
            font-weight: bold;
            color: #4D4F5C;
            margin-bottom: 0px;
        }
    }
}

.FilterForm {
    padding: 15px 30px;
    max-width: $sitewidth;
    margin: 0 auto;

    form {
        justify-content: space-between;
        border-bottom: 1px solid #D0D0D0;

        .ant-row {

            .ant-col {
                flex: 0 0 20%;
                max-width: 20%;
                align-items: center;
                display: flex;

                @media screen and (max-width: $break-laptoplg1440) {
                    flex: 0 0 25%;
                    max-width: 25%;
                }

                @media screen and (max-width: $break-ipadproportrait) {
                    flex: 0 0 50%;
                    max-width: 50%;

                    &:last-child {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }

                @media screen and (max-width: $break-mobilesmall) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .ant-form-item {
                    width: 100%;
                    margin-bottom: 20px;
                    padding: 10px;

                    @media screen and (max-width: $break-ipadproportrait) {

                        display: block;
                    }

                    .ant-col {
                        flex: 0 0 50%;
                        max-width: 50%;

                        @media screen and (max-width: $break-ipadproportrait) {
                            flex: 0 0 100%;
                            max-width: 100%;
                            display: block;
                        }

                    }

                    .ant-form-item-label {

                        label {
                            font-size: 14px;
                            color: #4D4E53;

                            width: 100%;
                            text-align: left;
                            white-space: initial;
                            height: auto;
                            height: auto;

                            &:after {
                                display: none;
                            }
                        }
                    }

                    .ant-form-item-control {
                        .ant-form-item-control-input {
                            width: 100%;
                        }
                    }
                }

                .ant-form-item-control {
                    .ant-form-item-control-input {
                        .ant-form-item-control-input-content {
                            .ant-select {
                                width: 100%;

                                .ant-select-selector {
                                    border-color: transparent !important;
                                    box-shadow: 0px 2px 5px 0px #0000001f;

                                    &:focus {}
                                }

                                @media screen and (max-width: $break-mobile580) {
                                    width: 100%;
                                }
                            }

                            .ant-picker {
                                border-color: transparent !important;
                                box-shadow: 0px 2px 5px 0px #0000001f;
                                width: 100%;

                                &.ant-picker-focused {
                                    border-color: transparent !important;
                                }

                                @media screen and (max-width: $break-mobile580) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                .BtnGroup {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    height: 75%;
                    width: 100%;
                    flex-wrap: wrap;

                    @media screen and (max-width: $break-ipadproportrait) {
                        height: 100%;
                    }

                    button {
                        background-color: #202A44;
                        border-color: #202A44;
                        color: #fff;
                        font-size: 14px;
                        border: none;
                        box-shadow: none;
                        border-radius: 0px;
                        margin: 0px 5px;
                        min-width: 105px;

                        @media screen and (max-width: $break-mobilesmall) {
                            min-width: 100%;
                            margin: 5px 0px;
                        }

                        &:hover {
                            border: none;
                            box-shadow: none;

                        }

                    }
                }
            }
        }

    }

    &.OngoingFilter {
        .ant-row {
            width: 100%;
            justify-content: space-between;

            .ant-col {
                flex: 0 0 20%;
                max-width: 20%;

                @media screen and (max-width: $break-ipadprolandscape) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                @media screen and (max-width: $break-mobilesmall) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .BtnGroup {
            width: 100%;
            text-align: right;
            margin-bottom: 20px;

            button {
                background-color: #1251B5;
                color: #fff;
                font-size: 14px;
                border: none;
                box-shadow: none;
                border-radius: 0px;
                margin: 0px 5px;
                min-width: 105px;

                @media screen and (max-width: $break-mobilesmall) {
                    min-width: 100%;
                    margin: 5px 0px;
                }

                &:hover {
                    border: none;
                    box-shadow: none;

                }

            }
        }
    }

    &.PendingReview {
        .ant-row {
            width: 100%;

            .ant-col {
                flex: 0 0 30%;
                max-width: 30%;

                @media screen and (max-width: $break-ipadprolandscape) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                @media screen and (max-width: $break-mobilesmall) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .BtnGroup {
            width: 100%;
            text-align: right;
            margin-bottom: 20px;

            button {
                background-color: #1251B5;
                color: #fff;
                font-size: 14px;
                border: none;
                box-shadow: none;
                border-radius: 0px;
                margin: 0px 5px;
                min-width: 105px;

                @media screen and (max-width: $break-mobilesmall) {
                    min-width: 100%;
                    margin: 5px 0px;
                }

                &:hover {
                    border: none;
                    box-shadow: none;

                }

            }
        }
    }

    &.formexpandedfilter {
        form {
            .ant-row {
                width: 100%;

                .ant-col {

                    .ant-form-item {
                        display: block;

                        .ant-col {
                            flex: 0 0 100%;
                            max-width: 100%;

                            label {
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .BtnGroup {
                        margin-bottom: 0px;
                        height: unset;
                        margin-top: 12px;

                        @media screen and (max-width: $break-ipadproportrait) {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }

    }
}

.CaseListData {
    padding: 15px 30px;
    max-width: $sitewidth;
    margin: 0 auto;

    form {
        .ant-input-group {
            width: 465px;
            max-width: 100%;
            margin-bottom: 20px;

            @media screen and (max-width: $break-mobile580) {
                width: 100%;
            }

            .ant-input-group-wrapper {
                .ant-input-wrapper {
                    position: relative;

                    .ant-input-affix-wrapper {
                        padding: 10px 40px;
                        border-radius: 30px;
                        box-shadow: 3px 4px 15px 2px #0000001f;
                    }

                    .ant-input-group-addon {
                        position: absolute;
                        left: 10px;
                        top: 7px;
                        z-index: 9;

                        button {
                            border: none;
                            background-color: transparent;

                            &:focus {
                                border: none;
                                box-shadow: none;
                                outline: none;

                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-table-wrapper {

        .ant-table-content {

            table {
                width: 100%;
                thead {
                    tr {
                        th {
                            background-color: #D8D7D5;
                            border-left: 1px solid #fff;
                          
                            &:last-child() {
                                border-left: none;
                            }
                            td{
                                font-size:18px;
                            }
                        }
                        
                    
                    }
                }

            }
        }

    }

    .OnGoingCasesTable {
        thead {
            tr {
                th {

                    &:nth-child(2),
                    &:nth-child(3) {
                        color: $white;
                    }
                }
            }
        }
    }


    &.TableWrapAction {
        table {
            tbody {
                tr {
                    td {
                        button {
                            border-color: #4D4E53;
                            color: #4D4E53;

                            &.filled {
                                border-color: $primary;
                                background-color: $primary;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        &.Actionlarge {
            table {
                width: 1650px;
            }
        }
    }
    
}