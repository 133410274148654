.closetabfilter{
    @media screen and (max-width:1340px) {
            overflow-x: auto;
    }
    table{
        thead{
            tr{
                th{
                    text-align: center;
                }
            }
        }
        @media screen and (max-width:1340px) {
            width: 450px;
        }
    }
    .ant-row{
        .ant-col{
            max-width: 100% !important;
            flex: none !important;
        }
    }
    .BtnGroup{
        margin-bottom: 10px;
        margin-top: 10px;
        width: 100%;
        text-align: right;
        button{
            background-color: #1251B5;
            color: #fff;
            font-size: 14px;
            border: none;
            box-shadow: none;
            border-radius: 0px;
            margin: 0px 5px;
            min-width: 105px;
        }
    }
}