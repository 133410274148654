.LogNewCasesWrap {
  padding: 0px 1px;

  .HeaderHighlight {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0px 0px 25px 0px #0000001f;
    align-items: center;
    max-width: $sitewidth;
    margin: 20px auto;

    @media screen and (max-width: $break-mobile) {
      display: block;
    }

    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #4D4F5C;
      margin-bottom: 0px;
    }

    .ButtonWrap {
      @media screen and (max-width: $break-mobile) {
        margin-top: 30px;
      }

      button {
        background-color: $primary;
        color: $white;
        font-size: 14px;
        border: none;
        box-shadow: none;
        border-radius: 0px;
        margin: 0px 5px;
        min-width: 105px;

        @media screen and (max-width: $break-mobile) {
          margin-top: 10px;
        }
      }
    }
  }

  .Selector {
    max-width: $sitewidth;
    margin: 0px auto;

    form {
      // max-width: 300px;
      margin-top: 30px;
      padding: 0px 15px;

      .ant-row {
        margin-bottom: 10px;

        .ant-form-item-label {
          text-align: left;
          white-space: normal;

          label {
            &:after {
              display: none;
            }
          }

        }
      }
    }

    h4 {
      font-size: 20px;
      font-weight: bold;
    }

    @media screen and (max-width: $break-belowlaptop) {
      padding: 0px 15px;
    }
  }

  .ant-tabs {
    max-width: $sitewidth;
    margin: 0px auto;

    @media screen and (max-width: $break-belowlaptop) {
      padding: 0px 15px;
    }

    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        width: 100%;

        .ant-tabs-nav-list {
          width: 100%;
          border-bottom: 2px solid #7E8088;

          .ant-tabs-tab {
            width: 33%;
            &:hover, &:focus {
              color: $primary !important;
            }
            @media screen and (max-width: $break-ipadproportrait) {

              width: auto;
            }

            .ant-tabs-tab-btn {
              width: 100%;
              text-align: center;
              font-size: 20px;

              @media screen and (max-width: $break-ipadproportrait) {
                font-size: 16px;

              }

              &:hover, &:focus {
                color: $primary !important;
              }
            }

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $primary;
              }
            }
          }

          .ant-tabs-ink-bar {
            background: $primary;
            bottom: -1px;
          }
        }
      }
    }

    .ant-tabs-content-holder {
      .ant-tabs-content {
        .ant-tabs-tabpane {
          form {
            margin-top: 20px;

            .ant-row {
              .ant-col {
                h4 {
                  margin-bottom: 25px;
                  font-size: 14px;
                  font-weight: bold;
                  color: #4D4F5C;
                }

                .ant-form-item {
                  .ant-form-item-label {
                    text-align: left;
                    white-space: normal;

                    label {
                      font-size: 14px;
                      font-weight: 400;
                      color: #4D4F5C;
                      pointer-events: none;
                      caret-color: transparent !important;

                      &:after {
                        display: none;
                      }
                    }

                  }

                  .ant-form-item-control {
                    .ant-form-item-control-input {
                      .ant-form-item-control-input-content {
                        .ant-upload {
                          width: 100%;

                          .ant-upload {
                            button {
                              width: 100%;
                              position: relative;
                              text-align: left;
                              border: 1px solid #CDD4D9;
                              border-radius: 5px;
                              color: #999999;

                              img {
                                position: absolute;
                                right: 0px;
                                border-left: 1px solid #CDD4D9;
                                padding: 8px;
                                top: 0;
                                height: 100%;
                              }

                              &:hover {
                                color: #999999;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .ant-form-item-control-input-content {

                  // .ant-upload-list-item-card-actions-btn {
                  //   display: none !important;
                  // }
                }

                .btn-label-overclip {
                  span {
      
                    display: inline-block;
                    flex: auto;
                    display: inline-block;
                    width: 85%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }

                .AddField {
                  text-align: right;

                  button {
                    background-color: $primary;
                    color: $white;
                    width: 107px;
                    height: 38px;
                    font-size: 14px;
                  }
                }

                table {
                  width: 100%;

                  thead {
                    tr {
                      th {
                        background-color: #D8D7D5;
                        height: 57px;
                        color: #000;
                        font-size: 18px;
                        width: 50%;
                        font-weight: normal;
                        text-align: center;

                        &:nth-child(1) {
                          border-right: 1px solid $white;
                          border-top-left-radius: 10px;
                        }

                        &:nth-child(2) {
                          border-top-right-radius: 10px;
                        }
                      }
                    }
                  }

                  tbody {
                    tr {
                      td {
                        padding: 10px;
                        text-align: center;
                        font-size: 12px;

                        button {
                          background-color: $primary;
                          color: $white;
                          font-size: 12px;

                        }
                      }

                      &:nth-child(even) {
                        background-color: #F5F5F5;
                      }
                    }
                  }
                }
              }
            }

            .FootWrap {
              text-align: center;

              button {
                width: 150px;
                height: 38px;
                font-size: 14px;
                margin: 30px 10px;

                &.plane {
                  color: #4D4E53;
                  border-color: #4D4E53;
                  background-color: $white;
                }

                &.filled {
                  background-color: $primary;
                  color: $white;
                  border-color: $primary;
                }

                @media screen and (max-width: $break-mobile380) {
                  margin: 10px;
                }
              }
            }
          }
        }
      }
    }
  }


  .custom-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
  }
}

.applicant-upload-block {
  .ant-col.ant-col-16 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}