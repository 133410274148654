.FieldDetailTabs {
  padding: 0px 30px;
  margin-top: 20px;
  max-width: $sitewidth;
  margin: 0 auto;

  .ant-card {
    border-radius: 10px;
    margin-bottom: 10px;

    .ant-card-body {
      border-radius: 10px;
      box-shadow: 0px 0px 25px 0px #0000001f;
      // margin-bottom: 10px;

      .NodataCard {
        text-align: center;
        font-size: 25px;
        padding: 20px 0px;
        text-transform: uppercase;
      }
    }
  }

  .InnerTabsWrap {
    .ActionButtonHeader {
      text-align: right;

      button {
        background-color: $primary;
        color: $white;
        font-size: 14px;
        border-color: transparent;
        border: none;
        box-shadow: none;
        height: 38px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .ant-row {
    .ant-col {
      .UserNameselector {
        max-width: 600px;
      }

      .ant-form-item {
        .ant-form-item-label {
          text-align: left;
          font-weight: bold;
        }
      }

      &.ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            input {
              width: 100%;
            }

            .ant-picker {
              width: 100%;
            }
          }
        }
      }

      input {
        &.EditableField {
          margin-bottom: 10px;
          width: 100%;
          height: 32px;
        }
      }

      table {
        width: 100%;

        thead {
          tr {
            th {
              background-color: #D8D7D5;
              height: 57px;
              color: #000;
              font-size: 18px;
              width: 50%;
              font-weight: normal;
              text-align: center;

              &:nth-child(1) {
                border-right: 1px solid $white;
                border-top-left-radius: 10px;
              }

              &:nth-child(2) {
                border-top-right-radius: 10px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 10px;
              text-align: center;
              font-size: 12px;

              button {
                background-color: $primary;
                color: $white;
                font-size: 12px;

                &.delete {
                  background-color: #a91111;
                  margin-left: 5px;

                  &:hover {
                    border-color: #a91111;
                  }
                }
              }
            }

            &:nth-child(even) {
              background-color: #F5F5F5;
            }
          }
        }
      }

      .DocColumn {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            display: flex;
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;

            span {
              width: 100%;
              margin-right: 10px;
            }

            >button {
              background-color: $primary;
              color: $white;
              width: 107px;
              height: 32px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .InfoCard {
    .EditCard {
      display: flex;
      justify-content: space-between;

      button {
        background-color: $primary;
        color: #fff;
        font-size: 14px;
        border-color: transparent;
        border: none;
        box-shadow: none;
        height: 38px;
        margin-right: 10px;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .ant-tabs {
    .ant-collapse {
      border: none;

      .ant-collapse-item {
        border: none;

        &.ant-collapse-item-active {
          .ant-collapse-header {
            &:before {
              transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -o-transform: rotate(0deg);
            }
          }
        }

        .ant-collapse-header {
          border: none;
          background-color: #D8D7D5;
          font-size: 20px;
          font-weight: bold;
          color: #4D4E53;

          .ant-collapse-arrow {
            display: none;
          }

          &:before {
            content: '';
            height: 12px;
            width: 20px;
            background-image: url('../images/arrowdown.png');
            background-size: contain;
            position: absolute;
            right: 10px;
            background-repeat: no-repeat;
            top: 25px;
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
          }
        }

        .ant-collapse-content {
          .ant-collapse-content-box {
            .ant-row {
              .ant-col {
                .infoWrap {
                  h4 {
                    font-size: 20px;
                    font-weight: bold;
                    color: #4D4E53;
                    margin-bottom: 20px;
                  }
                }

                .infoWrap-kyc {
                  table {
                    width: 100%;

                    thead {
                      tr {
                        th {
                          &:nth-child(2) {
                            border-top-right-radius: 0px;
                          }

                          &:nth-child(3) {
                            border-top-right-radius: 10px;
                          }
                        }
                      }
                    }
                  }
                }


                .TableWrap {
                  overflow-x: auto;

                  table {
                    width: 100%;

                    thead {
                      tr {
                        background-color: #D8D7D5;

                        th {
                          padding: 20px;
                          font-weight: normal;
                          font-size: 16px;
                        }

                      }
                    }

                    tbody {
                      tr {
                        td {
                          padding: 20px;
                          font-weight: normal;
                          font-size: 16px;
                        }

                        &:nth-child(odd) {
                          background-color: #F5F5F5;
                        }

                        p {
                          &.exprireBlue {
                            font-size: 14px;
                            color: #1251B5;
                          }

                          &.exprirered {
                            font-size: 14px;
                            color: #D32316;
                          }
                        }
                      }
                    }
                  }
                }

                .infoWrap-kyc-v2 {
                  .TableWrap {
                    border-top-left-radius: 10px !important;
                    border-top-right-radius: 10px !important;

                    .kyc-summary-header {
                      th {
                        border-radius: unset !important;
                        padding-top: 6px !important;
                      }

                      .kyc-summary-inner-paragraph {
                        border-top-left-radius: 0px !important;
                        border-top-right-radius: 0px !important;
                      }
                    }
                  }
                }

                .CardBlock {
                  margin-bottom: 20px;

                  h3 {
                    font-size: 20px;
                    font-weight: bold;
                    color: #4D4F5C;
                  }

                  .ant-card {
                    .ant-card-body {
                      .ant-row {
                        .ant-col {
                          h4 {
                            font-size: 14px;
                            color: #25285D;
                            font-weight: bold;
                          }

                          p {
                            font-size: 14px;
                            font-weight: normal;
                            color: #4D4F5C;
                            text-align: right;

                            @media screen and (max-width: $break-ipad) {
                              text-align: left;
                            }
                          }
                        }
                      }
                    }
                  }
                }


              }
            }
          }
        }
      }
    }
  }

  .CaseDetailHeader {

    max-width: $sitewidth;
    margin: 0 auto;

    .InnerWrap {
      margin-top: 20px;
      padding: 15px 20px;
      box-shadow: 0px 0px 25px 0px #0000001f;
      margin-bottom: 20px;

      .ant-row {
        align-items: center;

        .ant-col {
          @media screen and (max-width: $break-ipadprolandscape) {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: left !important;
            margin-top: 30px;
          }

          p {
            &.CaseId {
              margin-bottom: 0px;
              font-size: 20px;
              font-weight: bold;
              color: #4D4F5C;

              @media screen and (max-width: $break-ipadproportrait) {
                font-size: 16px;
              }

              span {
                font-weight: normal;
                display: inline-block;

                // margin-left: 40px;
                @media screen and (max-width: $break-mobile) {
                  margin-left: 0px;
                }

                span {
                  border: 1px solid #4D4E53;
                  margin-left: 20px;
                  padding: 0px 15px;

                  @media screen and (max-width: $break-mobile) {
                    padding: 0px 15px;
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            text-align: right;

            button {
              background-color: $primary;
              color: #fff;
              font-size: 14px;
              border-color: transparent;
              border: none;
              box-shadow: none;
              height: 38px;
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .HeaderPanelAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: $break-mobile580) {
      display: block;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      color: #4D4F5C;

      @media screen and (max-width: $break-ipadproportrait) {
        font-size: 16px;
      }
    }

    button {
      background-color: $primary;
      color: $white;
      border-color: $primary;
      border-radius: 0px;
    }

    .ButtonWrap {

      button {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    .HeadingButton {
      display: flex;
      align-items: center;

      @media screen and (max-width: $break-ipadproportrait) {
        display: block;
      }

      h3 {
        margin-bottom: 0px;
      }

      button {
        margin-right: 10px;
        border-color: #4D4E53;
        color: #4D4E53;
        background-color: #fff;
      }
    }
  }

  .TableWrap {
    overflow-x: auto;
    margin-bottom: 20px;

    table {
      width: 100%;


      thead {
        tr {
          background-color: #d8d7d5;

          th {
            padding: 20px;
            font-weight: normal;
            font-size: 16px;
            text-align: left;
          }
        }
      }

      tbody {
        tr {
          &.Selected {
            background-color: #B2DFEE !important;
            color: black !important;
          }

          td {
            padding: 20px;
            font-weight: normal;
            font-size: 16px;
            text-align: left;

            a {
              font-size: 13px;
              font-weight: bold;
              color: $primary;
              text-decoration: underline;
            }
          

            button {
              background-color: $primary;
              color: $white;
              border-color: $primary;
              border-radius: 0px;
              margin-right: 10px;

              a {
                background-color: $primary;
                color: $white;
                border-color: $primary;
                text-decoration: none;
                font-weight: 400;

                &:hover {
                  text-decoration: none;
                  color: $white;

                }

              }
            }
          }

          &:nth-child(odd) {
            // background-color: #f5f5f5;
            background-color: #fff;
          }

          p {
            &.exprireBlue {
              font-size: 14px;
              color: #1251b5;
            }

            &.exprirered {
              font-size: 14px;
              color: #d32316;
            }
          }
        }
      }
    }

   

    .TableSmall {
      width: 80%;
      margin: 0 auto;

      @media screen and (max-width: $break-MDPI) {
        width: 100%;
        margin: unset;
      }

      table {
        border: 1px solid #999999;

        thead {
          tr {
            th {
              padding: 5px;
              background-color: #D1E5E5;
              font-size: 14px;
              text-align: center;
            }
          }

        }

        tbody {
          tr {
            td {
              font-size: 14px;
              padding: 5px;
              background-color: #fff;
              border-right: 1px solid #999999;

              &:last-child {
                border-right: none;
              }
            }
          }
        }
      }
    }
  }

  .CommentCard {
    .ant-card-body {
      height: 280px;
      overflow-y: auto;

      .commentlist {
        border-bottom: 2px solid #ddd;
        font-size: 15px !important;

        .postedBlock {
          font-size: 14px !important;
        }
      }

      // overflow-x: hidden;
      overflow-wrap: break-word;
    }
  }

  .CommentCardScroll {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;


    .postedBlock {
      padding-right: 15px;
    }

    .thumb-right {
      position: relative;
      right: 15px;
    }

  }

  .CommentCardScroll::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .CommentCardScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .CommentCardScroll::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 10px;
  }

  /* Handle on hover */
  .CommentCardScroll::-webkit-scrollbar-thumb:hover {
    background: #333;
  }

  .kyc-detail-v2 {
    .ant-card-bordered {
      max-height: 350px;
      overflow-y: auto;
      height: 100%;
      box-shadow: 0px 0px 25px 0px #0000001f;


      .ant-card-body {
        height: 100%;
        box-shadow: none !important;

      }
    }

    .CardBlock {
      margin-bottom: 30px !important;
      height: 100%;
    }

    .ant-card-bordered::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .ant-card-bordered::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    .ant-card-bordered::-webkit-scrollbar-thumb {
      background: #333;
      border-radius: 10px;
    }

    /* Handle on hover */
    .ant-card-bordered::-webkit-scrollbar-thumb:hover {
      background: #333;
    }
  }
}

.FieldTabsWrap {
  padding: 0px 15px;
  margin-top: 20px;
  max-width: $sitewidth;
  margin: 0 auto;

  .popbox {
      .popcard {
          margin-bottom: 20px;
          box-shadow: 0px 0px 25px 0px #0000001f;

          .ant-card-body {
              padding: 10px 24px;

              h3 {
                  color: $primary;
                  font-size: 20px;
                  font-weight: bold;

                  @media screen and (max-width: $break-belowlaptop) {
                      font-size: 15px;
                  }
              }

              h4 {
                  font-weight: normal;
                  color: #4D4E53;
                  font-size: 20px;

                  @media screen and (max-width: $break-belowlaptop) {
                      font-size: 15px;
                  }
              }
          }
      }
  }

  .ant-tabs {
      .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            
             
              .ant-tabs-nav-list {
                  width: calc(100% - 1px);
                  border-bottom: 2px solid #7E8088;
                  // justify-content: space-between;
                  margin: 0 auto;

                  .ant-tabs-tab {
                      width: 35%;

                      @media screen and (max-width: $break-ipadproportrait) {

                          width: auto;
                      }

                      .ant-tabs-tab-btn {
                          width: 100%;
                          text-align: center;
                          font-size: 20px;

                          @media screen and (max-width: $break-ipadproportrait) {
                              font-size: 16px;

                          }

                          &:hover {
                              color: $primary;
                          }
                      }

                      &.ant-tabs-tab-active {
                          .ant-tabs-tab-btn {
                              color: $primary;
                          }
                      }

                      &:hover {
                          color: #202A44 !important;
                      }
                  }

                  .ant-tabs-ink-bar {
                      background: $primary;
                      bottom: -1px;
                  }
              }
          }
      }

      .ant-tabs-content-holder {
          .ant-tabs-content {
              .ant-tabs-tabpane {
                  .DataWrap {
                      .CardDetailWrap {
                          h3 {
                              margin: 20px 0px;
                              font-size: 20px;
                              font-weight: bold;
                          }

                          .ant-card {
                              border-radius: 10px;

                              .ant-card-body {
                                  padding: 30px 20px;
                                  box-shadow: 0px 0px 25px 0px #0000001f;

                                  .ant-row {
                                      .ant-col {
                                          h4 {
                                              font-size: 14px;
                                              font-weight: bold;
                                          }

                                          p {
                                              font-size: 14px;
                                              font-weight: normal;
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  }

  .InnerTabsWrap {
      margin-bottom: 50px;

      .ant-card {
          border-radius: 10px;

          .ant-card-body {
              box-shadow: 0px 0px 25px 0px #0000001f;

              .ant-tabs {
                  .ant-tabs-nav {
                      &:before {
                          display: none;
                      }

                      .ant-tabs-nav-wrap {
                          padding-bottom: 20px;

                          .ant-tabs-nav-list {

                              .ant-tabs-tab {
                                  width: 33%;

                                  @media screen and (max-width: $break-ipad) {
                                      width: auto;
                                  }

                                  .ant-tabs-tab-btn {
                                      color: #747373;
                                  }

                                  &.ant-tabs-tab-active {
                                      &:after {
                                          content: '';
                                          position: absolute;
                                          bottom: -10px;
                                          margin: 0 auto;
                                          z-index: 999;
                                          left: 0;
                                          right: 0;
                                          width: 0;
                                          height: 0;
                                          border-left: 10px solid transparent;
                                          border-right: 10px solid transparent;

                                          border-top: 10px solid #4D4E53;
                                      }

                                      .ant-tabs-tab-btn {
                                          color: #4D4E53;
                                      }
                                  }
                              }

                              .ant-tabs-ink-bar {
                                  background-color: #4D4E53;
                              }

                          }

                      }
                  }

                  .ant-tabs-content-holder {
                      .FormWrap {
                          form {
                              .ant-row {
                                  .ant-col {
                                      .UserNameselector {
                                          max-width: 600px;
                                      }

                                      .ant-form-item {
                                          .ant-form-item-label {
                                              text-align: left;
                                              font-weight: bold;

                                              label {
                                                  position: relative;

                                                  &:before {
                                                      position: absolute;
                                                      right: -10px;
                                                  }
                                              }
                                          }
                                      }

                                      &.ant-form-item-control {
                                          .ant-form-item-control-input {
                                              .ant-form-item-control-input-content {
                                                  input {
                                                      width: 100%;
                                                  }

                                                  .ant-picker {
                                                      width: 100%;
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }

                              .UploadWrap {
                                  h3 {
                                      font-size: 20px;
                                      font-weight: bold;
                                      margin-bottom: 40px;
                                  }

                                  .ant-form-item {
                                      .ant-form-item-control {
                                          .ant-form-item-control-input {
                                              .ant-form-item-control-input-content {
                                                  .ant-upload {
                                                      width: 100%;

                                                      button {
                                                          width: 100%;
                                                          text-align: left;

                                                          &:hover {
                                                              border-color: $primary;
                                                              color: $primary;
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }

                          &.ViewField {
                              .viewonly {
                                  .ant-form-item {
                                      .ant-form-item-control-input {
                                          .ant-form-item-control-input-content {
                                              border: 1px solid #ddd;
                                              padding: 10px;

                                              .ant-upload-list {
                                                  .ant-upload-list-text-container {
                                                      .ant-upload-list-item {
                                                          .ant-upload-list-item-info {
                                                              .ant-upload-span {
                                                                  .ant-upload-text-icon {
                                                                      display: none;
                                                                  }

                                                                  >a {
                                                                      padding-left: 0px;
                                                                  }
                                                              }
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }


  }

  .InfoCard {
      margin-top: 30px;

      .CardDetailWrap {
          h3 {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 20px;
          }
      }
  }

  .CommunicationformWrap {
      .ant-row {
          .ant-col {
              padding: 10px;

              h4 {
                  margin-bottom: 25px;
                  font-size: 14px;
                  font-weight: bold;
                  color: #4D4F5C;
                  margin-top: 10px;
              }

              .ant-card {
                  min-height: 700px;
                  padding: 30px 20px;
                  box-shadow: 0px 0px 25px 0px #0000001f;
                  margin-bottom: 20px;

                  .ant-form-item {
                      .ant-form-item-label {
                          text-align: left;
                          white-space: normal;

                          label {
                              font-size: 14px;
                              font-weight: 400;
                              color: #4D4F5C;

                              &:after {
                                  display: none;
                              }
                          }

                      }

                      .ant-form-item-control {
                          .ant-form-item-control-input {
                              .ant-form-item-control-input-content {
                                  .ant-upload {
                                      width: 100%;

                                      .ant-upload {
                                          button {
                                              width: 100%;
                                              position: relative;
                                              text-align: left;
                                              border: 1px solid #CDD4D9;
                                              border-radius: 5px;
                                              color: #999999;

                                              img {
                                                  position: absolute;
                                                  right: 0px;
                                                  border-left: 1px solid #CDD4D9;
                                                  padding: 8px;
                                                  top: 0;
                                                  height: 100%;
                                              }

                                              &:hover {
                                                  color: #999999;
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }

                  .ant-card-body {
                      .ButtonAddon {
                          text-align: right;

                          margin: 10px 0px;

                          button {
                              background-color: $primary;
                              color: $white;
                              font-size: 14px;
                              border-color: transparent;
                              border: none;
                              box-shadow: none;
                              height: 38px;
                              margin-right: 10px;
                              margin-bottom: 10px;
                          }
                      }
                  }

                  .ant-card {
                      min-height: auto;
                      padding: 10px;
                      margin-bottom: 20px;

                      .ant-card-body {
                          padding: 10px;

                          p {
                              font-size: 16px;
                          }
                      }
                  }
              }

              .postedBlock {
                  text-align: right;
                  font-size: 14px;
                  color: #4D4F5C;
                  margin: 10px 0px;
                  position: relative;

                  span {
                      position: absolute;
                      left: 0;
                      background-color: $primary;
                      color: $white;
                      padding: 5px;

                      img {
                          width: 12px;
                          margin-left: 10px;
                          cursor: pointer;
                      }
                  }
              }
          }
      }
  }
}