.NavWrap {
    justify-content: space-around;
  background-color: #e1dfdd;

  @media screen and (max-width: $break-ipadproportrait) {
    background-color: #fff;
  }

  .MobileNav {
    display: none;
  }

  @media screen and (max-width: $break-ipadproportrait) {
    &.DeskNav {
      display: none;
    }

    background-color: #e1dfdd;
    border-bottom: none;
    height: 60px;

    .MobileNav {
      display: block;
      width: 100%;

      button {
        top: 7px;
        background-color: $primary;
        color: $white;
        font-size: 14px;
        border-color: transparent;
        border: none;
        box-shadow: none;
        height: 45px;
        margin-left: 10px;

        &.LogNewCaseMobile {
        top: 7px;
          // position: absolute;
          float: right;
          height: 45px;
          background-color: $primary;
          color: $white;
          font-size: 14px;
          border-color: transparent;
          border: none;
          box-shadow: none;
          width: 150px;

          @media screen and (max-width: $break-MDPI) {
            font-size: 14px;
            width: 160px;
          }

          @media screen and (max-width: $break-ipadproportrait) {
            top: 7px;
          }

          img {
            margin-right: 10px;
            width: 18px;
          }

          &:focus,
          &:hover {
            background-color: $primary;
            color: $white;
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .InnerNavWrap {
    display: flex; /* Use flexbox */
    
    // position: relative;

    > button {
        margin-right:6%;
      top: 6px;
      height: 45px;
      background-color: $primary;
      color: $white;
      font-size: 14px;
      border-color: transparent;
      border: none;
      box-shadow: none;
      width: 150px;

      @media screen and (max-width: $break-MDPI) {
        float: left;
        font-size: 14px;
        width: 160px;
      }

      @media screen and (max-width: $break-ipadproportrait) {
        top: -45px;
      }

      img {
        margin-right: px;
        width: 15px;
      }

      &:focus,
      &:hover {
        background-color: $primary;
        color: $white;
        border: none;
        box-shadow: none;
      }
    }
  }

  ul {
    @media screen and (max-width: $break-ipadproportrait) {
      display: none;
    }

    &:before {
      display: none;
    }

    &.ant-menu {
        margin-left: 10%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e1dfdd;
        flex-direction: row;
        overflow:hidden;
      // display: flex;
      background-color: #e1dfdd;
      // border-bottom: none;
      // height: 60px;
      width: 100%;
      

      li {
        margin: 0px !important;
        padding: 0px !important;
        height: 100%;
        font-size: 16px;
        line-height: 60px;

        &.ant-menu-item-selected {
          border-bottom: none;
        }

        &:hover {
          border-bottom: 0px !important;
          color: #4d4e53 !important;
        }

        a {
          color: #4d4e53;
          display: inherit;
          padding: 0px 20px;

          &:hover {
            color: #4d4e53;
          }

          &.active {
            border-bottom: none;
            color: #4d4e53;
            background-color: $white;
            top: 0px;
          }
        }
      }
    }
  }
}

.MobileMenu {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          .logowrap {
            img {
              width: 100%;
            }
          }

          ul {
            padding: 0px;

            li {
              list-style-type: none;

              a {
                color: #4d4e53;
                display: inherit;
                padding: 10px 0px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
