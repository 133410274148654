.topBarWrap {
    padding: 10px 15px !important;
    margin-bottom: 25px !important;
    border-bottom: 1px solid #CACED5;
    max-width: $sitewidth;
    margin: 0 auto;
    padding: 0 15px;

    @media screen and (max-width: $break-ipadproportrait) {
        display: none;
    }

    .logowrap {
        @media screen and (max-width: $break-mobile580) {
            text-align: center;
        }

        .Branding {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px 0;

            img {
                margin-right: 25px;
            }

            .text {
                display: flex;
                align-items: center;


                h4 {
                    margin-bottom: 0px;
                    font-weight: 800;
                    font-size: 18px;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 15px;
                }
            }
        }
    }

    .SignOutBlock {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        @media screen and (max-width: $break-mobile580) {
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .UserInfo {
            p {
                margin-bottom: 0px;
                font-size: 20px;
                color: $black;
                font-weight: 600;

                @media screen and (max-width: $break-ipad) {
                    font-size: 16px;
                }

                img {
                    margin-right: 10px;

                    @media screen and (max-width: $break-ipad) {
                        margin-right: 0px;
                    }
                }
            }

            svg {
                fill: rgba(0, 0, 0, 0.85);
            }
        }

        button {
            margin-left: 10px;
            background-color: transparent;
            height: 65px;
            // width: 186px;
            color: $white;
            font-size: 20px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            box-shadow: none;

            @media screen and (max-width: $break-ipad) {
                width: auto;
                font-size: 13px;
                margin-left: 10px;

            }

            img {
                // margin-right: 10px;

            }

            svg {
                fill: $primary;
            }
        }
    }
}

.notificationboxlayer {
    padding: 10px !important;

    .NotificationBox {
        max-height: 300px;
        overflow-y: auto;
        min-width: 340px;
        padding: 10px;
        border-radius: 2px;
        box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33) !important;


        li {
            font-size: 13px;
            font-weight: 500;
            padding: 0px;
            border: none;
            line-height: unset;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #333;

            &:hover {
                color: initial;
            }

            span {
                svg {
                    fill: #1251b5;
                    height: 15px;
                    width: 15px;
                }
            }

            &.notifydetail {
                display: block;
                height: auto;
                line-height: initial;
                border-bottom: 1px solid #ddd;
                padding: 5px 20px 8px 5px;
                border-left: 4px solid $primary;
                position: relative;

                &:after {
                    display: none;
                }

                >a {
                    >div {
                        &:nth-child(3) {
                            position: absolute;
                            top: 0;
                            right: 0px
                        }
                    }

                    &:hover {
                        color: #000;
                    }
                }
            }
        }
    }

    .viewallnotification {
        border-bottom: unset;
        background-color: #202A44;
        margin-top: 10px;
        padding: 10px;
        color: #fff;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;

        span {
            svg {
                fill: #fff;
                margin-right: 10px;
            }
        }
    }

    .noNotify {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: center;
        margin-top: -20px;
        background-color: #fff;
        font-size: 15px;
        font-weight: 700;
    }
}

.NotificationBox::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.NotificationBox::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.NotificationBox::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 10px;
}

/* Handle on hover */
.NotificationBox::-webkit-scrollbar-thumb:hover {
    background: #333;
}

.UserDropdown {
    ul {
        li {
            text-align: center;
        }
    }
}