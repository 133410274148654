.ViewDataCardWrap {
    padding: 0px 30px;
    max-width: $sitewidth;
    margin: 0 auto;

    .ant-card {
        box-shadow: 0px 0px 25px 0px #0000001f;
        margin-top: 30px;

        .ant-card-body {
            .HeaderSelector {
                box-shadow: 0px 3px 6px 0px #0000001f;
                padding: 30px 20px;

                form {
                    @media screen and (max-width: $break-mobile580) {
                        display: block;
                    }

                    .ant-form-item {
                        margin-right: 50px;

                        @media screen and (max-width: $break-MDPI) {
                            margin-right: 10px;
                        }

                        @media screen and (max-width: $break-ipadproportrait) {
                            margin-right: 0px;
                            margin-bottom: 20px;
                            flex: 0 0 50%;
                        }

                        @media screen and (max-width: $break-mobile580) {
                            flex: 0 0 100% !important;
                        }

                        .ant-form-item-label {

                            label {
                                font-size: 16px;
                                color: #4D4E53;
                                margin-right: 7px;
                            }
                        }
                    }

                    .ant-form-item-control {
                        .ant-form-item-control-input {
                            .ant-form-item-control-input-content {
                                .ant-select {
                                    width: 200px;

                                    .ant-select-selector {
                                        border-color: transparent !important;
                                        box-shadow: 0px 2px 5px 0px #0000001f;

                                        &:focus {}
                                    }

                                    @media screen and (max-width: $break-mobile580) {
                                        width: 100%;
                                    }
                                }

                                .ant-picker {
                                    border-color: transparent !important;
                                    box-shadow: 0px 2px 5px 0px #0000001f;

                                    &.ant-picker-focused {
                                        border-color: transparent !important;
                                    }

                                    @media screen and (max-width: $break-mobile580) {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .BoxWrap {
                // margin-top: 15%;
                margin-top: 6%;
                .CardBox {
                    background-color: #F3F5F6;
                    max-width: 350px;
                    min-height: 200px;
                    padding: 20px;
                    position: relative;
                    box-shadow: 0px 3px 6px 0px #0000001f;
                    margin: 5px;
                    margin-bottom: 90px;

                    @media screen and (max-width: $break-ipadproportrait) {
                        text-align: center;
                    }

                    .textWrap {
                        .ImageWrap {
                            position: absolute;
                            top: -50px;
                            width: 140px;
                            text-align: center;
                            background: #fff;
                            box-shadow: 0px 3px 6px 0px #0000001f;
                            height: 107px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @media screen and (max-width: $break-ipadproportrait) {
                                position: relative;
                                top: unset;
                            }

                            @media screen and (max-width: $break-ipadproportrait) {
                                margin: 0 auto;
                                width: auto;
                            }
                        }

                        .number {
                            text-align: right;
                            padding-bottom: 20px;
                            font-size: 36px;

                            @media screen and (max-width: $break-ipadproportrait) {
                                text-align: center;
                            }
                        }

                        p {
                            font-size: 18px;
                            font-weight: bold;
                            margin-bottom: 0px;

                            // @media screen and (max-width: $break-laptoplg1440){
                            //     font-size: 20px;
                            // }
                            @media screen and (max-width: $break-belowlaptop) {
                                font-size: 14px;
                            }
                        }
                    }


                }

                .ant-col.ant-col-6:nth-child(4n+1):nth-last-child(-n+4),
                .ant-col.ant-col-6:nth-child(4n+1):nth-last-child(-n+4)~.ant-col.ant-col-6 {
                    .CardBox {
                     margin-bottom: 20px !important;
                    }
                }
            }
        }
    }
}