.AuthWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .InnerWrap{
        box-shadow: 0px 12px 22px 0px #ccc;
        .Branding{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 25px;
            img{
                margin-right: 10px;
            }
            .text{
                display: flex;
                align-items: center;
                flex-direction: column;
                h4{
                    margin-bottom: 0px;
                    font-weight: 800;
                    font-size: 18px;
                }
                p{
                    margin-bottom: 0px;
                    font-size: 15px;
                }
            }
        }
        .HeadWrap{
            background-color: $lite;
            h3{
                padding: 10px;
                text-align: center;
                font-size: 37px;
                font-weight: 800;
                margin-bottom: 0px;
            }
        }
        form{
            width: 457px;
            max-width: 100%;
            padding: 20px 40px;
            min-height: 400px;
            .ant-form-item-label{
                label{
                    font-size: 16px;
                    color: $litedark;
                    &:before{
                        display: none;
                    }
                }
            }
            .ant-form-item-control{
                .ant-form-item-control-input{
                    .ant-form-item-control-input-content{
                        input{
                            height: 44px;
                            border-radius: 100px;
                            padding: 4px 11px;
                        }
                        .ant-input-password{
                            border-radius: 100px;
                            padding: 0px;
                            .ant-input-suffix{
                                margin-right: 10px;
                            }
                        }
                        button{
                            width: 100%;
                            height: 50px;
                            background-color: $primary;
                            border-color: $primary;
                            font-size: 16px;
                            font-weight: 700;
                            
                            &.ChangePassword{
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
            .ForgetLink{
                text-align: center;
                a{
                    color: $primary;
                }
            }
            
        }
    }
    
}