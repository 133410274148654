.KycWrap{
    .InnerTabsWrap{
        .ant-tabs{
            .ant-tabs-nav{
                &:before{
                    display: none;
                }
                .ant-tabs-nav-wrap{
                   padding-bottom: 20px;
                    .ant-tabs-nav-list{
                       
                        .ant-tabs-tab{
                            width: 33%;
                           
                            .ant-tabs-tab-btn{
                               color: #D8D7D5;
                            }
                          
                            &.ant-tabs-tab-active{
                                &:after{
                                    content: '';
                                    position: absolute;
                                    bottom: -10px;
                                    margin: 0 auto;
                                    z-index: 999;
                                    left: 0;
                                    right: 0;
                                    width: 0; 
                                    height: 0; 
                                    border-left: 10px solid transparent;
                                    border-right: 10px solid transparent;
                                    
                                    border-top: 10px solid #4D4E53;
                                }
                                .ant-tabs-tab-btn{
                                    color: #4D4E53;
                                }
                            }
                        }
                        .ant-tabs-ink-bar{
                            background-color: #4D4E53;
                        }
                    }
                  
                }
            }
            .ant-tabs-content-holder{
                .ant-tabs-content{
                    .ant-tabs-tabpane{
                        .ant-card{
                            .ant-card-body{
                                .headerWrap{
                                    margin-bottom: 15px;
                                }
                                .ant-collapse{
                                    border:none;
                                    .ant-collapse-item{
                                        border:none;
                                        &.ant-collapse-item-active{
                                            .ant-collapse-header{
                                                &:before{
                                                    transform: rotate(0deg);
                                                    -webkit-transform: rotate(0deg);
                                                    -moz-transform: rotate(0deg);
                                                    -ms-transform: rotate(0deg);
                                                    -o-transform: rotate(0deg);
                                                }
                                            }
                                        }
                                        .ant-collapse-header{
                                            border:none;
                                           background-color: #D8D7D5;
                                           font-size: 20px;
                                           font-weight: bold;
                                           color: #4D4E53;
                                           .ant-collapse-arrow{
                                            display: none;
                                           }
                                           &:before{
                                               content: '';
                                               height: 12px;
                                               width: 20px;
                                               background-image: url('../images/arrowdown.png');
                                               background-size: contain;
                                               position: absolute;
                                               right: 10px;
                                               background-repeat: no-repeat;
                                               top: 25px;
                                               transform: rotate(-180deg);
                                               -webkit-transform: rotate(-180deg);
                                               -moz-transform: rotate(-180deg);
                                               -ms-transform: rotate(-180deg);
                                               -o-transform: rotate(-180deg);
                                           }
                                        }
                                        .ant-collapse-content{
                                            .ant-collapse-content-box{
                                                .ant-row{
                                                    .ant-col{
                                                        .infoWrap{
                                                            h4{
                                                                font-size: 20px;
                                                                font-weight: bold;
                                                                color: #4D4E53;
                                                                margin-bottom: 20px;
                                                            }
                                                        }
                                                        .TableWrap{
                                                            overflow-x: auto;
                                                            table{
                                                                width: 100%;
                                                                thead{
                                                                    tr{
                                                                        background-color: #D8D7D5;
                                                                        th{
                                                                            padding: 20px;
                                                                            font-weight: normal;
                                                                            font-size: 16px;
                                                                        }
                                                                    }
                                                                }
                                                                tbody{
                                                                    tr{
                                                                        td{
                                                                            padding: 20px;
                                                                            font-weight: normal;
                                                                            font-size: 16px;
                                                                        }
                                                                        &:nth-child(odd){
                                                                            background-color: #F5F5F5;
                                                                        }
                                                                        p{
                                                                            &.exprireBlue{
                                                                                font-size: 14px;
                                                                                color: #1251B5;
                                                                            }
                                                                            &.exprirered{
                                                                                font-size: 14px;
                                                                                color: #D32316;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .CardBlock{
                                                            margin-bottom: 20px;
                                                            h3{
                                                                font-size: 20px;
                                                                font-weight: bold;
                                                                color: #4D4F5C;
                                                            }
                                                            .ant-card{
                                                                .ant-card-body{
                                                                    .ant-row{
                                                                        .ant-col{
                                                                            h4{
                                                                                font-size: 14px;
                                                                                color: #25285D;
                                                                                font-weight: bold;
                                                                            }
                                                                            p{
                                                                                font-size: 14px;
                                                                                font-weight: normal;
                                                                                color: #4D4F5C;
                                                                                text-align: right;
                                                                                @media screen and (max-width: $break-ipad) {
                                                                                    text-align: left;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}