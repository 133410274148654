.GeneralTabsWrap {
    padding: 0px 15px;
    margin-top: 20px;
    max-width: $sitewidth;
    margin: 0 auto;

    .popbox {
        .popcard {
            margin-bottom: 20px;
            box-shadow: 0px 0px 25px 0px #0000001f;

            .ant-card-body {
                padding: 10px 24px;

                h3 {
                    color: $primary;
                    font-size: 20px;
                    font-weight: bold;

                    @media screen and (max-width: $break-belowlaptop) {
                        font-size: 15px;
                    }
                }

                h4 {
                    font-weight: normal;
                    color: #4D4E53;
                    font-size: 20px;

                    @media screen and (max-width: $break-belowlaptop) {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .ant-tabs {
        .ant-tabs-nav {
            .ant-tabs-nav-wrap {
                width: 100%;

                .ant-tabs-nav-list {
                    width: calc(100% - 1px);
                    border-bottom: 2px solid #7E8088;
                    // justify-content: space-between;
                    margin: 0 auto;

                    .ant-tabs-tab {
                        width: 25%;

                        @media screen and (max-width: $break-ipadproportrait) {

                            width: auto;
                        }

                        .ant-tabs-tab-btn {
                            width: 100%;
                            text-align: center;
                            font-size: 20px;

                            @media screen and (max-width: $break-ipadproportrait) {
                                font-size: 16px;

                            }

                            &:hover {
                                color: $primary;
                            }
                        }

                        &.ant-tabs-tab-active {
                            .ant-tabs-tab-btn {
                                color: $primary;
                            }
                        }

                        &:hover {
                            color: #202A44 !important;
                        }
                    }

                    .ant-tabs-ink-bar {
                        background: $primary;
                        bottom: -1px;
                    }
                }
            }
        }

        .ant-tabs-content-holder {
            .ant-tabs-content {
                .ant-tabs-tabpane {
                    .DataWrap {
                        .CardDetailWrap {
                            h3 {
                                margin: 20px 0px;
                                font-size: 20px;
                                font-weight: bold;
                            }

                            .ant-card {
                                border-radius: 10px;

                                .ant-card-body {
                                    padding: 30px 20px;
                                    box-shadow: 0px 0px 25px 0px #0000001f;

                                    .ant-row {
                                        .ant-col {
                                            h4 {
                                                font-size: 14px;
                                                font-weight: bold;
                                            }

                                            p {
                                                font-size: 14px;
                                                font-weight: normal;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .InnerTabsWrap {
        margin-bottom: 50px;

        .ant-card {
            border-radius: 10px;

            .ant-card-body {
                box-shadow: 0px 0px 25px 0px #0000001f;

                .ant-tabs {
                    .ant-tabs-nav {
                        &:before {
                            display: none;
                        }

                        .ant-tabs-nav-wrap {
                            padding-bottom: 20px;

                            .ant-tabs-nav-list {

                                .ant-tabs-tab {
                                    width: 33%;

                                    @media screen and (max-width: $break-ipad) {
                                        width: auto;
                                    }

                                    .ant-tabs-tab-btn {
                                        color: #747373;
                                    }

                                    &.ant-tabs-tab-active {
                                        &:after {
                                            content: '';
                                            position: absolute;
                                            bottom: -10px;
                                            margin: 0 auto;
                                            z-index: 999;
                                            left: 0;
                                            right: 0;
                                            width: 0;
                                            height: 0;
                                            border-left: 10px solid transparent;
                                            border-right: 10px solid transparent;

                                            border-top: 10px solid #4D4E53;
                                        }

                                        .ant-tabs-tab-btn {
                                            color: #4D4E53;
                                        }
                                    }
                                }

                                .ant-tabs-ink-bar {
                                    background-color: #4D4E53;
                                }

                            }

                        }
                    }

                    .ant-tabs-content-holder {
                        .FormWrap {
                            form {
                                .ant-row {
                                    .ant-col {
                                        .UserNameselector {
                                            max-width: 600px;
                                        }

                                        .ant-form-item {
                                            .ant-form-item-label {
                                                text-align: left;
                                                font-weight: bold;

                                                label {
                                                    position: relative;

                                                    &:before {
                                                        position: absolute;
                                                        right: -10px;
                                                    }
                                                }
                                            }
                                        }

                                        &.ant-form-item-control {
                                            .ant-form-item-control-input {
                                                .ant-form-item-control-input-content {
                                                    input {
                                                        width: 100%;
                                                    }

                                                    .ant-picker {
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .UploadWrap {
                                    h3 {
                                        font-size: 20px;
                                        font-weight: bold;
                                        margin-bottom: 40px;
                                    }

                                    .ant-form-item {
                                        .ant-form-item-control {
                                            .ant-form-item-control-input {
                                                .ant-form-item-control-input-content {
                                                    .ant-upload {
                                                        width: 100%;

                                                        button {
                                                            width: 100%;
                                                            text-align: left;

                                                            &:hover {
                                                                border-color: $primary;
                                                                color: $primary;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.ViewField {
                                .viewonly {
                                    .ant-form-item {
                                        .ant-form-item-control-input {
                                            .ant-form-item-control-input-content {
                                                border: 1px solid #ddd;
                                                padding: 10px;

                                                .ant-upload-list {
                                                    .ant-upload-list-text-container {
                                                        .ant-upload-list-item {
                                                            .ant-upload-list-item-info {
                                                                .ant-upload-span {
                                                                    .ant-upload-text-icon {
                                                                        display: none;
                                                                    }

                                                                    >a {
                                                                        padding-left: 0px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


    }

    .InfoCard {
        margin-top: 30px;

        .CardDetailWrap {
            h3 {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 20px;
            }
        }
    }

    .CommunicationformWrap {
        .ant-row {
            .ant-col {
                padding: 10px;

                h4 {
                    margin-bottom: 25px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #4D4F5C;
                    margin-top: 10px;
                }

                .ant-card {
                    min-height: 700px;
                    padding: 30px 20px;
                    box-shadow: 0px 0px 25px 0px #0000001f;
                    margin-bottom: 20px;

                    .ant-form-item {
                        .ant-form-item-label {
                            text-align: left;
                            white-space: normal;

                            label {
                                font-size: 14px;
                                font-weight: 400;
                                color: #4D4F5C;

                                &:after {
                                    display: none;
                                }
                            }

                        }

                        .ant-form-item-control {
                            .ant-form-item-control-input {
                                .ant-form-item-control-input-content {
                                    .ant-upload {
                                        width: 100%;

                                        .ant-upload {
                                            button {
                                                width: 100%;
                                                position: relative;
                                                text-align: left;
                                                border: 1px solid #CDD4D9;
                                                border-radius: 5px;
                                                color: #999999;

                                                img {
                                                    position: absolute;
                                                    right: 0px;
                                                    border-left: 1px solid #CDD4D9;
                                                    padding: 8px;
                                                    top: 0;
                                                    height: 100%;
                                                }

                                                &:hover {
                                                    color: #999999;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .ant-card-body {
                        .ButtonAddon {
                            text-align: right;

                            margin: 10px 0px;

                            button {
                                background-color: $primary;
                                color: $white;
                                font-size: 14px;
                                border-color: transparent;
                                border: none;
                                box-shadow: none;
                                height: 38px;
                                margin-right: 10px;
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .ant-card {
                        min-height: auto;
                        padding: 10px;
                        margin-bottom: 20px;

                        .ant-card-body {
                            padding: 10px;

                            p {
                                font-size: 16px;
                            }
                        }
                    }
                }

                .postedBlock {
                    text-align: right;
                    font-size: 14px;
                    color: #4D4F5C;
                    margin: 10px 0px;
                    position: relative;

                    span {
                        position: absolute;
                        left: 0;
                        background-color: $primary;
                        color: $white;
                        padding: 5px;

                        img {
                            width: 12px;
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.UploadWrap {
    .viewonly {
        .ant-row.ant-form-item {
            border: 1px solid #ddd;

            .ant-form-item-label {
                vertical-align: bottom;
                padding-bottom: 0;
                padding-left: 10px;
                padding-top: 10px;

            }

            .ant-upload-list-item-info {
                padding-left: 0 !important;
            }

            .ant-form-item-control-input-content {
                padding-top: 0 !important;
                border: none !important;
            }
        }

        .ant-upload-list-item {
            margin-top: 0;
        }
    }
}