@import 'App.scss';

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide {
  display: none !important;
}

.show {
  display: table-cell !important;
}

.CaseListData .OnGoingCasesTable thead tr th:nth-child(2),
.CaseListData .OnGoingCasesTable thead tr th:nth-child(3) {
  color: black !important;
}

.buttonPosition {
  text-align: right;
  margin: 20px;
}

.print {
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.badgeStyle {
  margin-right: 20px;
}

.error {
  color: rgb(228, 28, 28)
}

.filterbutton {
  margin-left: 75%;
  margin-bottom: 14px;
}

.fontStyle {
  font-size: 28px;
  font-weight: bold;
}

td {
  height: 30px;
  vertical-align: center;
}

/*Date picker css*/
.ant-picker-today-btn {
  color: #202A44;
}

.ant-input[disabled] {
  color: #101010c7 !important;
}

.ant-select {
  color: #101010c7 !important;

}

.ant-input {
  color: #101010c7 !important;
}

table {
  caret-color: transparent !important;
}

p {
  caret-color: transparent !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
img {
  caret-color: transparent !important;
}

label {
  caret-color: transparent !important;
}



.ant-select-disabled {
  .ant-select-selection-item {
    color: #101010c7 !important;
  }
}

.ant-tabs-tab-btn {
  caret-color: transparent !important;
}

.ant-modal-header {
  caret-color: transparent !important;
}

.pdfwrap{
  table{
    .PhDiv50Width{
      display: unset;
      box-sizing: unset !important;
     
    }
  }
}