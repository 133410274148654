.GeneratedReportWrap{
    max-width: $sitewidth;
    margin: 0 auto;
    padding: 0 15px;
    .BeureauHeader{
   
     
        .InnerWrap{
            margin-top: 20px;
            padding: 15px 20px;
            box-shadow: 0px 0px 25px 0px #0000001f;
            margin-bottom: 20px;
            .ant-row{
                align-items: center;
                .ant-col{
                    @media screen and (max-width: $break-MDPI) {
                        flex: 0 0 100%;
                        max-width: 100%;
                        text-align: left !important;
                        margin-top: 30px;
                    }
                    p{
                        &.CaseId{
                            margin-bottom: 0px;
                            font-size: 20px;
                            font-weight: bold;
                            color: #4D4F5C;
                            @media screen and (max-width: $break-ipadproportrait) {
                                font-size: 16px;
                            }
                            span{
                                font-weight: normal;
                                display: inline-block;
                                margin-left:0px;
                                @media  screen and (max-width: $break-mobile) {
                                    margin-left: 0px;
                                }
                                span{
                                    border: 1px solid #4D4E53;
                                    margin-left: 20px ;
                                    padding: 0px 15px;
                                   
                                    @media  screen and (max-width: $break-mobile) {
                                        padding: 0px 15px;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2){
                        text-align: right;
                        @media screen and (max-width: $break-ipad) {
                            text-align: left;
                            margin-top: 30px;
                        }
                        button{
                            background-color: $primary;
                            color: #fff;
                            font-size: 14px;
                            border-color: transparent;
                            border: none;
                            box-shadow: none;
                           height: 38px;
                           margin-right: 10px;
                           margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .popbox{
    
        .popcard{
            margin-bottom: 20px;
            box-shadow: 0px 0px 25px 0px #0000001f;
            .ant-card-body{
                padding: 10px 24px;
                h3{
                    color: $primary;
                    font-size: 20px;
                    font-weight: bold;
                    @media screen and (max-width: $break-ipad) {
                        font-size: 15px;
                    }
                }
                h4{
                    font-weight: normal;
                    color: #4D4E53;
                    font-size: 20px;
                    @media screen and (max-width: $break-ipad) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .TableWrap{
        .InnerWrap{
            overflow-x: auto;
        }
       h4{
           font-size: 20px;
           font-weight: bold;
           margin: 20px 0px;
           @media screen and (max-width: $break-ipad) {
            font-size: 15px;
        }
       }
       .ant-table-wrapper{
        
       
            overflow-x: auto;
            table{
                thead{
                    tr{
                        th{
                            background-color: #D8D7D5;
                            border-left: 1px solid #fff;
                           text-align: center;
                            &:nth-child(1){
                                border-right: none;
                                border-top-left-radius: 6px;
                               
                            }
                            &:nth-child(2){
                                border-left: none;
                                width: 150px;
                            }
                            &:last-child{
                                border-top-right-radius: 6px;
                            }
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            text-align: center;
                            padding: 15px;
                            a{
                                color: $primary;
                                text-decoration: underline;
                                font-weight: 600;
                            }
                            button{
                                background-color: $primary;
                                color: $white;
                                border-color: $primary;
                            }
                        }
                        &:nth-child(even){
                            background-color: #F5F5F5;
                        }
                        &.ant-table-row-selected{
                            
                            td{
                                
                                background: transparent;
                               
                            }
                        }
                    }
                }
                
            }
       
       
    }
    table{
        width: 100%;
        margin-bottom: 10px;
        thead{
            tr{
                th{
                    background-color: #D8D7D5;
                  
                   text-align: center;
                   padding: 15px;
                    &:nth-child(1){
                 
                        border-top-left-radius: 6px;
                       
                    }
                    &:last-child{
                        border-top-right-radius: 6px;
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    text-align: center;
                    padding: 15px;
                    a{
                        color: $primary;
                        text-decoration: underline;
                        font-weight: 600;
                    }
                    button{
                        background-color: $primary;
                        color: $white;
                        border-color: $primary;
                        &.imagebtn{
                            padding: 2px 5px;
                            margin-right: 10px;
                            height: 25px;
                            line-height: normal;
                            img{
                                width: 15px;
                                margin-top: -5px;
                            }
                        }
                    }
                }
                &:nth-child(even){
                    background-color: #F5F5F5;
                }
                &.ant-table-row-selected{
                    
                    td{
                        
                        background: transparent;
                       
                    }
                }
            }
        }
        
    }
    }
    .FootWrap{
        text-align: center;
        button{
            margin:20px 5px;
            width: 150px;
            height: 38px;
            font-size: 14px;
            color: #4D4E53;
            border-color: #4D4E53;
            background-color: $white;
            &.filled{
                background-color: $primary;
                color: $white;
                border-color: $primary;
            }
        }
    }
}