.BureauReportWrap {
  .InnerTabsWrap {
    .ant-tabs {
      .ant-tabs-nav {
        &:before {
          display: none;
        }

        .ant-tabs-nav-wrap {
          padding-bottom: 20px;

          .ant-tabs-nav-list {
            .ant-tabs-tab {
              width: 33%;

              .ant-tabs-tab-btn {
                color: #d8d7d5;
              }

              &.ant-tabs-tab-active {
                &:after {
                  content: "";
                  position: absolute;
                  bottom: -10px;
                  margin: 0 auto;
                  z-index: 999;
                  left: 0;
                  right: 0;
                  width: 0;
                  height: 0;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;

                  border-top: 10px solid #4d4e53;
                }

                .ant-tabs-tab-btn {
                  color: #4d4e53;
                }
              }
            }

            .ant-tabs-ink-bar {
              background-color: #4d4e53;
            }
          }
        }
      }

      .ant-tabs-content-holder {
        .ant-tabs-content {
          .HeaderPanelAction {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            @media screen and (max-width: $break-mobile580) {
              display: block;
            }

            h3 {
              font-size: 20px;
              font-weight: bold;
              color: #4D4F5C;

              @media screen and (max-width: $break-ipadproportrait) {
                font-size: 16px;
              }
            }

            button {
              background-color: $primary;
              color: $white;
              border-color: $primary;
              border-radius: 0px;
            }

            .ButtonWrap {

              button {
                margin-right: 10px;
                margin-bottom: 10px;
              }
            }

            .HeadingButton {
              display: flex;
              align-items: center;

              @media screen and (max-width: $break-ipadproportrait) {
                display: block;
              }

              h3 {
                margin-bottom: 0px;
              }

              button {
                margin-right: 10px;
                border-color: #4D4E53;
                color: #4D4E53;
                background-color: #fff;
              }
            }
          }

          .inquiryInputBlock {
            margin-bottom: 20px;

            .ant-card {
              .ant-card-head {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                margin-bottom: 0px;
                background-color: #1251b5;
                color: #fff;
                font-size: 18px;

                .ant-card-head-wrapper {
                  .ant-card-extra {
                    color: #fff;
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }

              .ant-card-body {
                .ant-row {
                  .ant-col {
                    p {
                      text-align: right;
                      font-size: 12px;

                      @media screen and (max-width: $break-ipad) {
                        text-align: left;
                      }
                    }
                  }
                }
              }
            }

            &.gridcard {
              .ant-card {
                margin-bottom: 20px;

                .ant-card-body {
                  .ant-row {
                    .ant-col {
                      .ant-card {
                        margin-bottom: 20px;

                        .ant-card-body {
                          padding: 0px;

                          .ant-row {
                            .ant-col {
                              h4 {
                                background-color: #d8d7d5;
                                padding: 20px;
                                margin-bottom: 0px;
                                font-size: 18px;
                                font-weight: 400;
                              }

                              p {
                                text-align: left;
                                padding: 20px;
                                font-size: 14px;

                                &:nth-child(even) {
                                  background-color: #F5F5F5
                                }
                              }

                              &:nth-child(2) {

                                h4,
                                p {
                                  text-align: right;

                                  @media screen and (max-width: $break-ipad) {
                                    text-align: left;
                                  }
                                }

                                p {}
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &.HighliteGrid {


              .ant-card {
                margin-bottom: 20px;

                .ant-card-body {
                  padding: 0px;

                  .ant-row {
                    .ant-col {
                      h4 {
                        background-color: #d8d7d5;
                        padding: 20px 10px;
                        margin-bottom: 0px;
                        font-size: 16px;
                        font-weight: 400;
                      }

                      p {
                        text-align: left;
                        padding: 20px;
                        font-size: 14px;

                        &:nth-child(even) {
                          background-color: #F5F5F5
                        }
                      }

                    }
                  }
                }
              }


            }
          }

          .TableWrap {
            overflow-x: auto;
            margin-bottom: 20px;

            table {
              width: 100%;

              @media screen and (max-width: $break-MDPI) {
                width: 1300px;
              }

              thead {
                tr {
                  background-color: #d8d7d5;

                  th {
                    padding: 20px;
                    font-weight: normal;
                    font-size: 16px;
                    text-align: center;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    padding: 20px;
                    font-weight: normal;
                    font-size: 16px;
                    text-align: center;

                    a {
                      font-size: 13px;
                      font-weight: bold;
                      color: $primary;
                      text-decoration: underline;
                    }

                    button {
                      background-color: $primary;
                      color: $white;
                      border-color: $primary;
                      border-radius: 0px;
                      margin-right: 10px;
                    }
                  }

                  &:nth-child(odd) {
                    background-color: #f5f5f5;
                  }

                  p {
                    &.exprireBlue {
                      font-size: 14px;
                      color: #1251b5;
                    }

                    &.exprirered {
                      font-size: 14px;
                      color: #d32316;
                    }
                  }
                }
              }
            }

            &.bolddata {
              .ant-card {
                margin-bottom: 20px;

                .ant-card-body {
                  padding: 0px;

                  table {
                    thead {
                      tr {

                        th {}
                      }
                    }

                    tbody {
                      tr {
                        td {
                          font-weight: bold;

                        }
                      }
                    }
                  }
                }
              }

            }

            .TableSmall {
              width: 80%;
              margin: 0 auto;

              @media screen and (max-width: $break-MDPI) {
                width: 100%;
                margin: unset;
              }

              table {
                border: 1px solid #999999;

                thead {
                  tr {
                    th {
                      padding: 5px;
                      background-color: #D1E5E5;
                      font-size: 14px;
                      text-align: center;
                    }
                  }

                }

                tbody {
                  tr {
                    td {
                      font-size: 14px;
                      padding: 5px;
                      background-color: #fff;
                      border-right: 1px solid #999999;

                      &:last-child {
                        border-right: none;
                      }
                    }
                  }
                }
              }
            }
          }

          .BoxCardTitle {
            .ant-card {
              margin-bottom: 20px;

              .ant-card-head {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                margin-bottom: 10px;
                background-color: $primary;
                color: $white;
                font-size: 18px;

                .ant-card-head-wrapper {
                  .ant-card-extra {
                    color: #fff;
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }

              .ant-card-body {
                padding: 0px;

                table {
                  thead {
                    tr {

                      th {
                        text-align: left;
                      }
                    }
                  }

                  tbody {
                    tr {
                      td {
                        text-align: left;
                      }
                    }
                  }
                }

                p {
                  text-align: right;
                  padding: 10px 20px;
                }
              }
            }

            .TableSmall {
              width: 80%;
              margin: 10px auto;
              overflow-x: auto;

              @media screen and (max-width: $break-MDPI) {
                width: 100%;
                margin: unset;
                padding-bottom: 20px;
              }

              table {
                border: 1px solid #999999;
                width: 100%;

                @media screen and (max-width: $break-MDPI) {
                  width: 1300px;
                }

                thead {
                  tr {
                    th {
                      padding: 5px;
                      background-color: #D1E5E5;
                      font-size: 14px;
                      text-align: center;
                      font-weight: normal;
                    }
                  }

                }

                tbody {
                  tr {
                    td {
                      font-size: 14px;
                      padding: 5px;
                      background-color: #fff;
                      border-right: 1px solid #999999;

                      &:last-child {
                        border-right: none;
                      }
                    }
                  }
                }
              }
            }

            &.GridCardLink {
              table {
                tr {
                  td {
                    a {
                      font-weight: 600;
                    }
                  }
                }
              }
            }

            &.TableInCard {

              .TableWrap {
                position: relative;
                padding-left: 20px;

                .taglabel {
                  position: absolute;
                  left: -39px;
                  top: 31px;
                  transform: rotate(90deg);
                  -webkit-transform: rotate(90deg);
                  -moz-transform: rotate(90deg);
                  -ms-transform: rotate(90deg);
                  -o-transform: rotate(90deg);

                  background-color: #1251b5;
                  color: #fff;

                  p {
                    padding: 0px;
                    text-align: left;
                    margin-bottom: 0px;

                    span {
                      display: inline-block;
                      padding: 5px 10px;
                      font-size: 12px;
                      background-color: #707070;

                      &.number {
                        background-color: transparent;
                        transform: rotate(-90deg);
                        -webkit-transform: rotate(-90deg);
                        -moz-transform: rotate(-90deg);
                        -ms-transform: rotate(-90deg);
                        -o-transform: rotate(-90deg);
                        margin-left: 10px;
                      }
                    }
                  }
                }

              }

              .TableSmall {
                width: 100%;
                padding: 0px 15px;
              }
            }

            &.HirarcgyGridTable {
              table.InnerTable {


                tbody {
                  tr {
                    background-color: transparent;

                    td {
                      background-color: transparent;
                      padding: 0px;
                      text-align: center;
                    }

                    &:nth-child(odd) {
                      background-color: transparent;
                    }
                  }
                }

                @media screen and (max-width: $break-MDPI) {
                  width: 100%;
                }
              }
            }
          }

          .InnerTabsApplicant {
            .ant-card {
              .ant-card-body {
                .ant-tabs {
                  .ant-tabs-nav-wrap {
                    .ant-tabs-nav-list {
                      .ant-tabs-tab {
                        width: 20%;

                        &.ant-tabs-tab-active {

                          &:after {
                            display: none;
                          }

                          .ant-tabs-tab-btn {
                            color: $primary;
                          }
                        }

                        .ant-tabs-tab-btn {
                          color: #4D4E53;
                        }
                      }

                      .ant-tabs-ink-bar {
                        background-color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.bureauReportViewActive {
  background-color: #526d8e3d !important;
  color: #202A44;
}

.bureau-report-summary-table {
  border-radius: 10px;

  h3 {
    background: #202A44;
    margin-bottom: 0;
    padding: 15px 20px;
    font-size: 18px;
    color: #fff;
  }

  .ant-card-bordered {
    border-radius: unset !important;
  }

  .ant-card-body {
    overflow-x: scroll;
    border-radius: unset !important;
  }

  table {
    width: 1420px !important;

    th,
    td {
      font-size: 14px !important;
      padding: 15px 3px !important;
    }
  }
}

.bureau-report-summary-table-2 {
  .ant-card-bordered {
    margin-bottom: 40px !important;
  }

  .ant-card-head,
  .ant-card-body {
    margin-bottom: 0 !important;
  }

  .TableWrap {
    margin-bottom: 0 !important;

    table {
      width: 1480px !important;

      th,
      td {
        text-align: center !important;
        font-size: 14px !important;
        padding: 15px 6px !important;
      }

      td:first-child,
      th:first-child {
        padding-left: 15px !important;
      }
    }
  }

  .TableSmall.bp-table-small {
    table {
      border: none !important;
    }

    border: 1px solid #e1e1e1 !important;
    width: 100% !important;
    border-radius: 6px;

    th,
    td {
      text-align: center;
      padding: 15px 5px !important;
      border: none !important;
    }
  }
}