.StepsWrap {
    &.ant-steps{
        margin-bottom: 20px;
        //  border-bottom: 2px solid #7E8088;
        .ant-steps-item{
            width: 33.33%;
           border-bottom: 2px solid #7E8088;
            .ant-steps-item-container{
                text-align: center;
                .ant-steps-item-icon{
                    display: none;
                }
                .ant-steps-item-content{
                    .ant-steps-item-title{
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        padding: 12px 0;
                        font-size: 20px;
                        background: transparent;
                        border: 0;
                        outline: none;
                        color: rgba(0, 0, 0, 0.85) !important;
                        &:after{
                            display: none;
                        }
                    }
                   
                   
                }
            }
            &.ant-steps-item-active{
                border-bottom-color: #1251b5;
                .ant-steps-item-container{
                    .ant-steps-item-content{
                        .ant-steps-item-title{
                            color: #1251b5 !important;
                        }
                    }
                }
            }
        }
    }

}
.MainBtnWrap{
    button{
        padding: 0px 60px;
        background-color: $primary;
        border-color: $primary;
        font-size: 14px;
        font-weight: normal;
        height: 38px;
        line-height: normal;
        margin-right: 15px;
        color: $white;
        margin-bottom: 10px;
        &.SaveBtn{
            color: $white !important;
            background-color: $primary !important;
            border-color: $primary !important;
        }
        &.plane{
            &:hover, &:focus{
                background-color: transparent !important;
                color: #000 !important;
            }
        }
        &:nth-child(1){
            background-color: $white;
            color: #4D4E53;
            border-color: #4D4E53;
            &:hover{
                background-color: $white;
                color: #4D4E53;
                border-color: #4D4E53;
            }
        }
        &:hover,&:focus{
            color: #fff;
            background-color: $primary !important;
            border-color: $primary !important;
        }
    }
    a{
        padding: 9px 60px;
        background-color: $primary;
        border-color: $primary;
        font-size: 14px;
        font-weight: normal;
        height: 38px;
        line-height: normal;
        margin-right: 15px;
        color: $white;
        margin-bottom: 10px;
        display: inline-block;
        &:hover{
            color: #fff;
            background-color: $primary;
            border-color: $primary;
        }
    }
}