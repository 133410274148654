.hunterTabs {
  padding: 0px 30px;
  margin-top: 20px;
  max-width: $sitewidth;
  margin: 0 auto;

  .ant-card {
    border-radius: 10px;
    margin-bottom: 10px;

    .ant-card-body {
      border-radius: 10px;
      box-shadow: 0px 0px 25px 0px #0000001f;
      // margin-bottom: 10px;

      .NodataCard {
        text-align: center;
        font-size: 25px;
        padding: 20px 0px;
        text-transform: uppercase;
      }
    }
  }

  .ant-tabs {
    .ant-collapse {
      border: none;

      .ant-collapse-item {
        border: none;

        &.ant-collapse-item-active {
          .ant-collapse-header {
            &:before {
              transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -o-transform: rotate(0deg);
            }
          }
        }

        .ant-collapse-header {
          border: none;
          background-color: #d8d7d5;
          font-size: 20px;
          font-weight: bold;
          color: #4d4e53;

          .ant-collapse-arrow {
            display: none;
          }

          &:before {
            content: "";
            height: 12px;
            width: 20px;
            background-image: url("../images/arrowdown.png");
            background-size: contain;
            position: absolute;
            right: 10px;
            background-repeat: no-repeat;
            top: 25px;
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
          }
        }

        .ant-collapse-content {
          .ant-collapse-content-box {
            .ant-row {
              .ant-col {
                .TableWrap {
                  overflow-x: auto;

                  table {
                    width: 100%;

                    thead {
                      tr {
                        background-color: #d8d7d5;

                        th {
                          padding: 20px;
                          font-weight: normal;
                          font-size: 16px;
                        }
                      }
                    }

                    tbody {
                      tr {
                        td {
                          padding: 20px;
                          font-weight: normal;
                          font-size: 16px;
                        }

                        &:nth-child(odd) {
                          background-color: #f5f5f5;
                        }

                        p {
                          &.exprireBlue {
                            font-size: 14px;
                            color: #1251b5;
                          }

                          &.exprirered {
                            font-size: 14px;
                            color: #d32316;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .CaseDetailHeader {
    max-width: $sitewidth;
    margin: 0 auto;

    .InnerWrap {
      margin-top: 20px;
      padding: 15px 20px;
      box-shadow: 0px 0px 25px 0px #0000001f;
      margin-bottom: 20px;

      .ant-row {
        align-items: center;

        .ant-col {
          @media screen and (max-width: $break-ipadprolandscape) {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: left !important;
            margin-top: 30px;
          }

          p {
            &.CaseId {
              margin-bottom: 0px;
              font-size: 20px;
              font-weight: bold;
              color: #4d4f5c;

              @media screen and (max-width: $break-ipadproportrait) {
                font-size: 16px;
              }

              span {
                font-weight: normal;
                display: inline-block;

                // margin-left: 40px;
                @media screen and (max-width: $break-mobile) {
                  margin-left: 0px;
                }

                span {
                  border: 1px solid #4d4e53;
                  margin-left: 20px;
                  padding: 0px 15px;

                  @media screen and (max-width: $break-mobile) {
                    padding: 0px 15px;
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            text-align: right;

            button {
              background-color: $primary;
              color: #fff;
              font-size: 14px;
              border-color: transparent;
              border: none;
              box-shadow: none;
              height: 38px;
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .TableWrap {
    overflow-x: auto;
    margin-bottom: 20px;

    table {
      width: 100%;

      thead {
        tr {
          background-color: #d8d7d5;

          th {
            padding: 20px;
            font-weight: normal;
            font-size: 16px;
            text-align: left;
          }
        }
      }

      tbody {
        tr {
          &.Selected {
            background-color: #b2dfee !important;
            color: black !important;
          }

          td {
            padding: 20px;
            font-weight: normal;
            font-size: 16px;
            text-align: left;

            a {
              font-size: 13px;
              font-weight: bold;
              color: $primary;
              text-decoration: underline;
            }

            button {
              background-color: $primary;
              color: $white;
              border-color: $primary;
              border-radius: 0px;
              margin-right: 10px;

              a {
                background-color: $primary;
                color: $white;
                border-color: $primary;
                text-decoration: none;
                font-weight: 400;

                &:hover {
                  text-decoration: none;
                  color: $white;
                }
              }
            }
          }

          &:nth-child(odd) {
            // background-color: #f5f5f5;
            background-color: #fff;
          }

          p {
            &.exprireBlue {
              font-size: 14px;
              color: #1251b5;
            }

            &.exprirered {
              font-size: 14px;
              color: #d32316;
            }
          }
        }
      }
    }

    &.bolddata {
      .ant-card {
        margin-bottom: 20px;

        .ant-card-body {
          padding: 0px;

          table {
            thead {
              tr {
              }
            }

            tbody {
              tr {
                td {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }

    .TableSmall {
      width: 80%;
      margin: 0 auto;

      @media screen and (max-width: $break-MDPI) {
        width: 100%;
        margin: unset;
      }

      table {
        border: 1px solid #999999;

        thead {
          tr {
            th {
              padding: 5px;
              background-color: #d1e5e5;
              font-size: 14px;
              text-align: center;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 14px;
              padding: 5px;
              background-color: #fff;
              border-right: 1px solid #999999;

              &:last-child {
                border-right: none;
              }
            }
          }
        }
      }
    }
  }

}


